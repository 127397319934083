import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Outlet } from "react-router-dom";
import NavMenu from "./Layout/NavMenu";
import Footer from "./Layout/Footer";
import { useAuth } from "./Contexts/AuthContext";
import { useToast } from "./Contexts/ToastContext";
import useSignalRForPersonalizedMessage from "./Hooks/useSignalRForPersonalizedMessage";
import UniversalToast from "./UI/UniversalToast";
import styles from "./Layout.module.css";

const Layout = () => {
  const [toastMessageBody, setToastMessageBody] = useState("");
  const { user } = useAuth();
  const { toasts, showToast, handleToastClick } = useToast();
  useSignalRForPersonalizedMessage(user?.ID, setToastMessageBody);

  useEffect(() => {
    if (toastMessageBody) {
      showToast(toastMessageBody, "", "primary");
      setToastMessageBody("");
    }
  }, [toastMessageBody, showToast]);

  const toastMassege = () => (
    <div className={styles.toastContainer}>
      {toasts.map((toast) => (
        <UniversalToast
          className={styles.toast}
          key={toast.id}
          id={toast.id}
          isOpen={true}
          header={toast.header}
          body={toast.body}
          icon={toast.type}
          toggle={() => handleToastClick(toast.id)}
        />
      ))}
    </div>
  );

  return (
    <div
      className="d-flex flex-column min-vh-100"
      style={{ background: "#f9f9f9" }}
    >
      <NavMenu />
      <Container className="flex-grow-1" tag="main">
        <Outlet />
      </Container>
      <Footer className="flex-shrink-0" />
      {toastMassege()}
    </div>
  );
};

export default Layout;
