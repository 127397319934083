import React from "react";
import { FormGroup, Label, FormFeedback } from "reactstrap";
import Select from "react-select";
import styles from "../Pages/ExhibitingACar.module.css";

//Стилі селект
const customStyles = {
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "var(--dark-gray)" : "var(--dark-gray)",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "var(--dark-gray)",
  }),
};

const SelectFormGroup = ({
  label,
  value,
  onChange,
  options,
  error,
  errorMessage,
  formSubmitted,
  ...rest
}) => {
  const handleSelectChange = (selectedOption) => {
    onChange(selectedOption);
  };

  const showError =
    formSubmitted &&
    (error || !value) &&
    errorMessage !== "" &&
    errorMessage !== undefined;

  return (
    <FormGroup>
      <Label className={styles.labelExhibitingACar}>
        {label} <span className={styles.spanStar}>*</span>
      </Label>
      <Select
        className={styles.select}
        value={value}
        onChange={handleSelectChange}
        options={options}
        styles={{
          ...customStyles,
          control: (provided, state) => ({
            ...provided,
            fontSize: "17px",
            borderColor: state.isFocused
              ? "var(--dark-gray)"
              : showError
              ? "red"
              : "var(--dark-gray)",
            margin: "10px 0",
            background: "var(--light-gray)",
            padding: "5px 0",
            borderRadius: "12px",
            boxShadow: state.isFocused ? "none" : "none",
          }),
        }}
        {...rest}
        isInvalid={showError}
      />
      <FormFeedback
        style={{
          display: showError ? "block" : "none",
        }}
      >
        {errorMessage}
      </FormFeedback>
    </FormGroup>
  );
};

export default SelectFormGroup;
