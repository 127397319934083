import React, { useState } from "react";
import styles from "../ProfilePages/ProfileContent.module.css";

const FormRowSetingsUploadFile = ({ label, text }) => {
  const [files, setFiles] = useState([]);

  const handleUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [
      ...prevFiles,
      ...uploadedFiles.filter((file) => {
        return ["application/pdf", "image/png", "image/jpeg"].includes(
          file.type
        );
      }),
    ]);
  };

  const handleDeleteFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  return (
    <div className="row mb-3">
      <div className="col-sm-8">
        <div className="form-group">
          <label className={styles.lableSetings}>
            <strong>{label}</strong>
          </label>
          <div className="d-flex align-items-center mt-3">
            <p className={styles.formSetingsText}>{text}</p>
          </div>
        </div>
      </div>
      <div className="col-sm-4 d-flex justify-content-end align-items-end">
        <label
          htmlFor="file-upload"
          className={`float-right ${styles.btnPrimaryBlue}`}
        >
          <strong>Завантажити</strong>
          <input
            id="file-upload"
            type="file"
            style={{ display: "none" }}
            accept=".pdf, .png, .jpg, .jpeg"
            onChange={handleUpload}
            multiple
          />
        </label>
      </div>
      {files.length > 0 && (
        <div className={`col-sm-8 ${styles.fileFolder}`}>
          <div className={styles.fileList}>
            {files.map((file, index) => (
              <div key={index} className={styles.fileItem}>
                {file.type.startsWith("image/") ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt="Uploaded"
                    className={styles.uploadedImage}
                  />
                ) : (
                  <p className="mt-3 mb-3">{file.name}</p>
                )}
                <button
                  type="button"
                  className={styles.deleteButton}
                  onClick={() => handleDeleteFile(index)}
                >
                  Видалити
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default FormRowSetingsUploadFile;
