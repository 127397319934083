import React from "react";
import TextBlock from "../UI/TextBlock";

const AboutUs = () => {
  return (
    <TextBlock color="#dbf6fa" className="cardText">
      <div>
        <p>
          <strong>Сarcool</strong> це найкращий онлайн-аукціон для купівлі та
          продажу автомобілів. Хоча існує багато місць де можна придбати
          автомобіль, <strong>Сarcool</strong> пропонує значні переваги в
          порівнянні з іншими веб-сайтами.
          <br />
          <strong>Ось лише деякі з наших переваг:</strong>
        </p>
        <ul>
          <li>
            <strong>Низька комісія.</strong> Продавці продають безкоштовно, а
            комісія покупця становить лише 4,5%, мінімум 90у.о. що набагато
            менше ніж у альтернативних видів компаній{" "}
          </li>
          <li>
            <strong>Історія автомобіля.</strong> Кожен транспортний засіб
            повинен мати звіт про історію автомобіля , тому ми надаємо його
            безкоштовно, замість того, щоб просити продавців платити за їх
            отримання.{" "}
          </li>
          <li>
            <strong>Швидкий продаж.</strong> У той час як в інших місцях
            потрібні тижні або навіть місяці, щоб ваш автомобіль був проданий,
            ми якнайшвидше розмістимо ваш автомобіль на аукціон, а через тиждень
            у вас будуть гроші на руках і ви вже зможете придбати для себе інше
            авто, можливо не покидаючи сайт аукціону 🙂{" "}
          </li>
          <li>
            <strong>
              Carcool — це найзручніший автомобільний онлайн-ринок
            </strong>{" "}
            із легким сортуванням і пошуком, а також спрощеними аукціонами, які
            повідомляють вам саме те, що вам потрібно знати про кожен
            автомобіль.{" "}
          </li>
          <li>
            <strong>Підтримка.</strong> Ми завжди тут, щоб допомогти, навіть
            після завершення аукціону . Ми створили покроковий контрольний
            список, щоб забезпечити безперебійний продаж, і ми просто звяжемось,
            якщо у вас виникнуть запитання.
          </li>
        </ul>
      </div>
    </TextBlock>
  );
};

export default AboutUs;
