import React from "react";
import styles from "../Pages/ExhibitingACar.module.css";

const UploadArea = ({ files, setFiles, mainPhoto, setMainPhoto, id }) => {
  const handleDrop = (event) => {
    event.preventDefault();
    const newFiles = [...event.dataTransfer.files];
    const filteredFiles = filterFiles(newFiles);
    setFiles((prevFiles) => [...prevFiles, ...filteredFiles]);
    if (!mainPhoto && setMainPhoto && filteredFiles.length > 0) {
      const firstImage = filteredFiles.find((file) =>
        file.type.startsWith("image/")
      );
      if (firstImage) {
        setMainPhoto(firstImage);
      }
    }
  };

  const handleFileInput = (event) => {
    const newFiles = [...event.target.files];
    const filteredFiles = filterFiles(newFiles);
    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles, ...filteredFiles];
      if (!mainPhoto && setMainPhoto && filteredFiles.length > 0) {
        const firstImage = filteredFiles.find((file) =>
          file.type.startsWith("image/")
        );
        if (firstImage) {
          setMainPhoto(firstImage);
        }
      }
      return updatedFiles;
    });
  };

  const filterFiles = (files) => {
    return Array.from(files).filter((file) => {
      return file.type.startsWith("image/") || file.type.startsWith("video/");
    });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleRemove = (index) => {
    const newFiles = [...files];
    const removedFile = newFiles.splice(index, 1)[0];
    setFiles(newFiles);
    if (mainPhoto && setMainPhoto && mainPhoto === removedFile) {
      const nextMainPhoto =
        newFiles.find((file) => file.type.startsWith("image/")) || undefined;
      setMainPhoto(nextMainPhoto);
    }
  };

  const handleFileClick = () => {
    document.getElementById(id).click();
  };

  const handleMainPhotoChange = (event, file) => {
    event.stopPropagation();
    if (setMainPhoto) {
      setMainPhoto(file);
    }
  };

  return (
    <div
      className={`mb-4 ${styles.uploadArea}`}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onClick={handleFileClick}
    >
      <img
        src="/images/Send_file.png"
        alt="Send_file"
        style={{ width: "60px" }}
      />
      <p>Клікніть сюди для вибору фото, або перетягніть їх сюди</p>
      <input
        id={id}
        type="file"
        onChange={handleFileInput}
        style={{ display: "none" }}
        accept="image/*, video/*"
        multiple
      />
      <div className={styles.files}>
        {files.map((file, index) => (
          <div key={index} className={styles.divImgesOrVideo}>
            <img
              src="/images/Cancel.png"
              alt="Cancel"
              className={styles.imgCancelBtn}
              onClick={(event) => {
                event.stopPropagation();
                handleRemove(index);
              }}
            />
            {file.type.startsWith("image/") && (
              <>
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  className={styles.fileImgVideo}
                />
                {setMainPhoto && (
                  <div className={styles.mainPhotoToggle}>
                    <label
                      className="d-flex justify-content-center"
                      onClick={(event) => event.stopPropagation()}
                    >
                      <input
                        className={styles.selectMainPhoto}
                        type="radio"
                        name="mainPhoto"
                        checked={mainPhoto === file}
                        onChange={(event) => handleMainPhotoChange(event, file)}
                      />
                      {mainPhoto === file ? (
                        <strong>
                          <p style={{ marginBottom: "0" }}>Головне фото</p>
                        </strong>
                      ) : (
                        <strong>
                          <p style={{ marginBottom: "0" }}>Зробити головним</p>
                        </strong>
                      )}
                    </label>
                  </div>
                )}
              </>
            )}
            {file.type.startsWith("video/") && (
              <video
                src={URL.createObjectURL(file)}
                alt={file.name}
                className={styles.fileImgVideo}
                controls
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadArea;
