import React, { useState } from "react";
import TextBlock from "../UI/TextBlock";
import TodoList from "../UI/TodoList";
import ButtonEditModeration from "../UI/ButtonEditModeration";
import ButtonSaveOrCancel from "../UI/ButtonSaveOrCancel";
import styles from "./CardDeteilsModeration.module.css";

const CarDescriptionEdit = ({
  car,
  onSaveChangesDescription,
  editMode,
  setEditMode,
}) => {
  const [editedСarDescription, setEditedСarDescription] = useState(
    car.carDescription || ""
  );
  const [editedServiceHistory, setEditedServiceHistory] = useState(
    car.serviceHistoryDescription || ""
  );
  const [editedComplectation, setEditedComplectation] = useState(
    car.completeSetOfCars || []
  );
  const [editedModifications, setEditedModifications] = useState(
    car.modifiedList || []
  );
  const [editedDisadvantages, setEditedDisadvantages] = useState(
    car.disadvantagesList || []
  );

  const handleEdit = (blockName) => {
    setEditMode({ ...editMode, [blockName]: true });
  };

  const handleCancelEdit = (blockName) => {
    setEditMode({ ...editMode, [blockName]: false });
    if (blockName === "carDescription") {
      setEditedСarDescription(car.carDescription || "");
    } else if (blockName === "serviceHistory") {
      setEditedServiceHistory(car.serviceHistoryDescription || "");
    } else if (blockName === "completeSetOfCars") {
      setEditedComplectation(car.completeSetOfCars);
    } else if (blockName === "modifiedList") {
      setEditedModifications(car.modifiedList);
    } else if (blockName === "disadvantagesList") {
      setEditedDisadvantages(car.disadvantagesList);
    }
  };

  const handleSaveChanges = (blockName) => {
    if (editMode[blockName]) {
      let changes = {};
      if (blockName === "carDescription") {
        changes = { carDescription: editedСarDescription };
      } else if (blockName === "serviceHistory") {
        changes = { serviceHistoryDescription: editedServiceHistory };
      } else if (blockName === "completeSetOfCars") {
        const editedComplectationFiltered = editedComplectation.filter(
          (item) => item.trim() !== ""
        );
        changes = { completeSetOfCars: editedComplectationFiltered };
      } else if (blockName === "modifiedList") {
        const editedModificationsFiltered = editedModifications.filter(
          (item) => item.trim() !== ""
        );
        changes = { modifiedList: editedModificationsFiltered };
      } else if (blockName === "disadvantagesList") {
        const editedDisadvantagesFiltered = editedDisadvantages.filter(
          (item) => item.trim() !== ""
        );
        changes = { disadvantagesList: editedDisadvantagesFiltered };
      }
      onSaveChangesDescription(changes);
    }
    // Скидання режиму редагування після збереження
    setEditMode({ ...editMode, [blockName]: false });
  };

  const colors = ["var(--background-blue)", "var(--bs-white)"];
  let colorIndex = 0;

  const getNextColor = () => {
    const color = colors[colorIndex];
    colorIndex = (colorIndex + 1) % colors.length;
    return color;
  };

  return (
    <>
      {car.carDescription && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextBlock
            color={getNextColor()}
            fontSize={22}
            className="cardText col-xxl-8"
          >
            <h2>
              <strong>Опис від продавця</strong>{" "}
              <a
                href={car.ownerID ? `Profile?userId=${car.ownerID}` : "#"}
                className={styles.ownerUrl}
              >
                <img
                  alt="profile"
                  src="/images/profile1.png"
                  style={{ width: "40px" }}
                />{" "}
                <strong>{car.ownerName}</strong>
              </a>
            </h2>
            {editMode.carDescription ? (
              <textarea
                value={editedСarDescription}
                onChange={(e) => setEditedСarDescription(e.target.value)}
                className={styles.textareaDescription}
                style={{
                  background: "var(--bs-white)",
                }}
              />
            ) : (
              car.carDescription
            )}
          </TextBlock>

          {!editMode.carDescription ? (
            <ButtonEditModeration
              handleClick={() => handleEdit("carDescription")}
            />
          ) : (
            <ButtonSaveOrCancel
              handelSave={() => handleSaveChanges("carDescription")}
              handelCencel={() => handleCancelEdit("carDescription")}
            />
          )}
        </div>
      )}
      {car.completeSetOfCars.length !== 0 && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextBlock
            color={getNextColor()}
            fontSize={22}
            className={`cardText col-xxl-8 ${styles.descriptionCar}`}
          >
            <h2>
              <strong>Комплектація</strong>
            </h2>
            {editMode.completeSetOfCars ? (
              <>
                <TodoList
                  placeholder="Додати ще комплектація..."
                  todos={editedComplectation}
                  setTodos={setEditedComplectation}
                />
              </>
            ) : (
              <ul>
                {car.completeSetOfCars.map((complectation, index) => (
                  <li key={index}>{complectation}</li>
                ))}
              </ul>
            )}
          </TextBlock>
          {!editMode.completeSetOfCars ? (
            <ButtonEditModeration
              handleClick={() => handleEdit("completeSetOfCars")}
            />
          ) : (
            <ButtonSaveOrCancel
              handelSave={() => handleSaveChanges("completeSetOfCars")}
              handelCencel={() => handleCancelEdit("completeSetOfCars")}
            />
          )}
        </div>
      )}
      {car.modifiedList.length !== 0 && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextBlock
            color={getNextColor()}
            fontSize={22}
            className={`cardText col-xxl-8 ${styles.descriptionCar}`}
          >
            <h2>
              <strong>Модифікації</strong>
            </h2>
            {editMode.modifiedList ? (
              <>
                <TodoList
                  placeholder="Додати ще модефікацію..."
                  todos={editedModifications}
                  setTodos={setEditedModifications}
                />
              </>
            ) : (
              <ul>
                {car.modifiedList.map((modification, index) => (
                  <li key={index}>{modification}</li>
                ))}
              </ul>
            )}
          </TextBlock>
          {!editMode.modifiedList ? (
            <ButtonEditModeration
              handleClick={() => handleEdit("modifiedList")}
            />
          ) : (
            <ButtonSaveOrCancel
              handelSave={() => handleSaveChanges("modifiedList")}
              handelCencel={() => handleCancelEdit("modifiedList")}
            />
          )}
        </div>
      )}
      {car.disadvantagesList.length !== 0 && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextBlock
            color={getNextColor()}
            fontSize={22}
            className={`cardText col-xxl-8 ${styles.descriptionCar}`}
          >
            <h2>
              <strong>Недоліки</strong>
            </h2>
            {editMode.disadvantagesList ? (
              <>
                <TodoList
                  placeholder="Додати ще недолік..."
                  todos={editedDisadvantages}
                  setTodos={setEditedDisadvantages}
                />
              </>
            ) : (
              <ul>
                {car.disadvantagesList.map((disadvantage, index) => (
                  <li key={index}>{disadvantage}</li>
                ))}
              </ul>
            )}
          </TextBlock>
          {!editMode.disadvantagesList ? (
            <ButtonEditModeration
              handleClick={() => handleEdit("disadvantagesList")}
            />
          ) : (
            <ButtonSaveOrCancel
              handelSave={() => handleSaveChanges("disadvantagesList")}
              handelCencel={() => handleCancelEdit("disadvantagesList")}
            />
          )}
        </div>
      )}
      {car.serviceHistoryDescription && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <TextBlock
            color={getNextColor()}
            fontSize={22}
            className={`cardText col-xxl-8 ${styles.descriptionCar}`}
          >
            <h2>
              <strong>Сервісна історія</strong>
            </h2>
            {editMode.serviceHistory ? (
              <>
                <textarea
                  value={editedServiceHistory}
                  onChange={(e) => setEditedServiceHistory(e.target.value)}
                  className={styles.textareaDescription}
                  style={{
                    background: getNextColor(),
                  }}
                />
              </>
            ) : (
              car.serviceHistoryDescription
            )}
          </TextBlock>
          {!editMode.serviceHistory ? (
            <ButtonEditModeration
              handleClick={() => handleEdit("serviceHistory")}
            />
          ) : (
            <ButtonSaveOrCancel
              handelSave={() => handleSaveChanges("serviceHistory")}
              handelCencel={() => handleCancelEdit("serviceHistory")}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CarDescriptionEdit;
