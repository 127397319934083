import React, { useEffect, useState } from "react";
import { Form, FormGroup } from "reactstrap";
import TextInput from "../../UI/TextInput";
import { useApi } from "../../Contexts/ApiContext";
import AuthButton from "../../UI/AuthButton";
import Identification from "../../UI/Identification";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Contexts/AuthContext";
import styles from "../../UI/Identification.module.css";

const Registration = () => {
  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    password: "",
    confirmidPassword: "",
    firstName: "",
    surname: "",
  });
  const [apiUrl, setApiUrl] = useState("");
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const apiUrlFromContext = useApi();
  const { registration } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setApiUrl(apiUrlFromContext);
  }, [apiUrlFromContext]);

  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!formData.userName) {
      errors.userName = "Ім'я на сайті не може бути пустим.";
    }
    if (!formData.firstName) {
      errors.firstName = "Ім'я не може бути пустим.";
    }
    if (!formData.surname) {
      errors.surname = "Прізвище не може бути пустим.";
    }
    if (!formData.email) {
      errors.email = "Email не може бути пустим.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Неправильний формат email.";
    }
    if (!formData.password) {
      errors.password = "Пароль не може бути пустим.";
    }
    if (formData.password !== formData.confirmidPassword) {
      errors.confirmidPassword = "Паролі не співпадають.";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setValidationErrors({});

    try {
      const result = await registration(formData, apiUrl);
      if (result.success === false) {
        setError(result.message);
      } else {
        navigate("/");
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const openLogin = () => {
    navigate("/login");
  };

  return (
    <Identification
      header="Реєстрація"
      contextText="Вже маєте аккаунт?"
      linkText="Увійти"
      clickEvent={openLogin}
      hideGoogleButton={true}
    >
      {/* Відображення загальних серверних помилок */}
      {error && (
        <div
          className={styles.errorMessage}
          dangerouslySetInnerHTML={{ __html: error }}
        />
      )}

      {/* Відображення помилок валідації */}
      {Object.keys(validationErrors).length > 0 && (
        <div className={styles.errorMessage}>
          {Object.values(validationErrors).map((err, index) => (
            <div key={index}>{err}</div>
          ))}
        </div>
      )}

      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <TextInput
            type="text"
            id="userName"
            placeholder="Ім'я на сайті"
            value={formData.userName}
            onChange={(value) => handleChange("userName", value)}
            error={validationErrors.userName}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            type="text"
            id="firstName"
            placeholder="Ім'я"
            value={formData.firstName}
            onChange={(value) => handleChange("firstName", value)}
            error={validationErrors.firstName}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            type="text"
            id="surname"
            placeholder="Прізвище"
            value={formData.surname}
            onChange={(value) => handleChange("surname", value)}
            error={validationErrors.surname}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            type="email"
            id="email"
            placeholder="Email"
            value={formData.email}
            onChange={(value) => handleChange("email", value)}
            error={validationErrors.email}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            type="password"
            id="password"
            placeholder="Пароль"
            value={formData.password}
            isPassword={true}
            onChange={(value) => handleChange("password", value)}
            error={validationErrors.password}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            type="password"
            id="confirmidPassword"
            placeholder="Підтвердіть пароль"
            value={formData.confirmidPassword}
            isPassword={true}
            onChange={(value) => handleChange("confirmidPassword", value)}
            error={validationErrors.confirmidPassword}
          />
        </FormGroup>

        <AuthButton type="submit">Зареєструватись</AuthButton>
        <div className={styles.userAgreement}>
          Реєструючісь, ви погоджуєтесь з <a href="/">користувацькою угодою.</a>
        </div>
      </Form>
    </Identification>
  );
};

export default Registration;
