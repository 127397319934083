import React, { useEffect, useRef } from "react";
import AboutUs from "../FAQText/AboutUs";
import HowItWorks from "../FAQText/HowItWorks";
import AccordionList from "../UI/AccordionList";
import NavFAQ from "../FAQText/NavFAQ";
import {
  questionsBuyers,
  questionSeller,
  questionsAboutDelivery,
  questionsRegistration,
} from "../../data/dataAcardion";
import "./FAQ.css";

function questionsAddId(questions) {
  return questions.map((question, index) => ({
    ...question,
    id: index + 1,
  }));
}

const FAQ = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const elementRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const howItWorksRefs = [useRef(null), useRef(null), useRef(null)];

  return (
    <div className="row">
      <div className="col-xxl-4 col-xl-12" style={{ maxWidth: "300px" }}>
        <NavFAQ elementRefs={elementRefs} howItWorksRefs={howItWorksRefs} />
      </div>
      <div className="col-xxl-8 col-xl-12">
        <h1 className="title-with-line" ref={elementRefs[0]}>
          Про нас
        </h1>

        <AboutUs />
        <h1 className="title-no-line" ref={elementRefs[1]}>
          Як це працює
        </h1>
        <HowItWorks elementRefs={howItWorksRefs} />
        <h1 className="title-no-line mb-4">Питання що часто задаються</h1>
        <h4
          className="h4-margin-bottom"
          style={{ marginLeft: "25px" }}
          ref={elementRefs[2]}
        >
          <strong>Питання покупця</strong>
        </h4>
        <AccordionList questions={questionsAddId(questionsBuyers)} />
        <h4
          className="h4-margin-bottom"
          style={{ marginLeft: "25px", marginTop: "40px" }}
          ref={elementRefs[3]}
        >
          <strong>Питання продавця</strong>
        </h4>
        <AccordionList questions={questionsAddId(questionSeller)} />
        <h4
          className="h4-margin-bottom"
          style={{ marginLeft: "25px", marginTop: "40px" }}
          ref={elementRefs[4]}
        >
          <strong>Поширені запитання щодо доставки</strong>
        </h4>
        <AccordionList questions={questionsAddId(questionsAboutDelivery)} />
        <h4
          className="h4-margin-bottom"
          style={{ marginLeft: "25px", marginTop: "40px" }}
          ref={elementRefs[5]}
        >
          <strong>Поширені запитання щодо реєстрації</strong>
        </h4>
        <AccordionList questions={questionsAddId(questionsRegistration)} />
      </div>
    </div>
  );
};

export default FAQ;
