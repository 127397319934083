import * as signalR from "@microsoft/signalr";

const url = `${process.env.REACT_APP_API_URL}/lotHub`;

const createSignalRConnectionForLot = (lotId) => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => localStorage.getItem("token"),
    })
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection
    .start()
    .then(() => {
      connection
        .send("JoinGroup", lotId)
        .catch((err) =>
          console.error(`Failed to join group LOT ${lotId}: `, err)
        );
    })
    .catch((err) =>
      console.error(`SignalR Connection Error for LOT ${lotId}: `, err)
    );

  return connection;
};

export default createSignalRConnectionForLot;
