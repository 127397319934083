import React from "react";
import styles from "./CarDeteils.module.css";

const CarTable = ({ car, handleLinkClick }) => {
  const fields1 = [
    { label: "Марка", value: car.brand?.value || "N/A" },
    { label: "Модель", value: car.model?.value || "N/A" },
    { label: "Пробіг", value: car.mileage || "N/A" },
    { label: "VIN", value: car.vin || "N/A" },
    { label: "Власник", value: "Чистий" },
    { label: "Місто", value: car.region?.value || "N/A" },
    {
      label: "Продавець",
      value: (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href={car.ownerID ? `Profile?userId=${car.ownerID}` : "#"}
          onClick={(event) => handleLinkClick(event)}
        >
          <img
            alt="profile"
            src="/images/profile1.png"
            style={{ width: "25px" }}
          />
          {car.ownerName || "N/A"}
        </a>
      ),
    },
  ];

  const fields2 = [
    {
      label: "Двигун/паливо",
      value: `${car.engine || "N/A"} ${car.fuelType?.value || "N/A"}`,
    },
    { label: "Привід", value: car.drive?.value || "N/A" },
    { label: "КПП", value: car.gearbox?.value || "N/A" },
    { label: "Кузов", value: car.body?.value || "N/A" },
    { label: "Колір", value: car.color || "N/A" },
    { label: "Колір салона", value: "Чорний" },
    { label: "Тип продавця", value: "Приватний" },
  ];

  return (
    <div className={styles.twoColumns}>
      <div className={styles.column}>
        <table className={styles.tableCar}>
          <tbody>
            {fields1.map((field, index) => (
              <tr key={index}>
                <td
                  className={`${styles.tableCell} ${styles.tableCellFirstTable}`}
                >
                  <strong>{field.label}</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  {field.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.column}>
        <table className={styles.tableCar}>
          <tbody>
            {fields2.map((field, index) => (
              <tr key={index}>
                <td
                  className={`${styles.tableCell} ${styles.tableCellSecondTable}`}
                >
                  <strong>{field.label}</strong>
                </td>
                <td className={`${styles.tableCell} ${styles.tableCellWhite}`}>
                  {field.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CarTable;
