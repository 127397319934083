export const carcoolReviews = [
  {
    users: ["Віктор Д.", "Іван П.", "Олексій К."],
    testimonials: [
      "Сервісом дуже задоволений,\nпродав своє авто дуже вигідно,\nшвидко, і найголовніше - без\nкомісії!",
      "Дуже зручний і швидкий сервіс, продав свій автомобіль менш як за тиждень!",
      "Рекомендую всім, хто шукає швидкий та надійний спосіб продати авто!",
    ],
  },
  {
    users: ["Марія Т.", "Олег І.", "Наталя С."],
    testimonials: [
      "Велика подяка Carcool за швидку та просту угоду! Продала свою машину без стресу та додаткових витрат!",
      "Неймовірно задоволений результатом співпраці з Carcool. Продаж мого авто був миттєвим, а комісійні відсутні!",
      "Продаж машини став легким завданням завдяки Carcool. Швидко, ефективно та безпечно!",
    ],
  },
  {
    users: ["Павло Р.", "Анна К. ", "Сергій В."],
    testimonials: [
      "Вражений швидкістю обробки моєї заявки на продаж авто. Дуже рекомендую сервіс Carcool!",
      "Надзвичайно задоволена роботою Carcool! Продала автомобіль у мить без головного болю!",
      "Carcool - це відмінний сервіс для тих, хто цінує свій час та гроші. Рекомендую з закритими очима!",
    ],
  },
  {
    users: ["Ірина М.", "Владислав П.", "Андрій А."],
    testimonials: [
      "Продаж мого автомобіля став простим та безпековим завдяки Carcool. Дякую за оперативність та професіоналізм!",
      "Carcool - це справжня знахідка для тих, хто шукає швидкий та надійний спосіб продати авто. 10/10!",
      "Ніяких зайвих клопотів та грошових втрат - лише швидкий та зручний продаж авто з Carcool!",
    ],
  },
  {
    users: ["Олександра Б.", "Віктор Л.", "Юрій С."],
    testimonials: [
      "Дуже задоволена якістю обслуговування та ефективністю роботи Carcool. Продаж мого авто пройшов бездоганно!",
      "Швидко, зручно та безпечно - ось як я б охарактеризував досвід співпраці з Carcool. Рекомендую всім!",
      "Carcool - це ідеальний варіант для тих, хто хоче продати автомобіль без зайвих турбот та стресу. Дякую за професіоналізм!",
    ],
  },
];
