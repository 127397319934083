import React from "react";

const TextBlock = (props) => {
  return (
    <div
      style={{ background: props.color, fontSize: props.fontSize }}
      className={props.className}
    >
      {props.children}
    </div>
  );
};

export default TextBlock;
