import React from "react";
import styles from "../Moderation/CardDeteilsModeration.module.css";

const ButtonSaveOrCancel = ({ handelSave, handelCencel }) => {
  return (
    <>
      <button onClick={handelSave} className={styles.buttonSave}>
        <strong>Зберегти</strong>
      </button>
      <button onClick={handelCencel} className={styles.buttonCancel}>
        <strong>Відмінити</strong>
      </button>
    </>
  );
};

export default ButtonSaveOrCancel;
