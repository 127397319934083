import React, { useState } from "react";
import styles from "./CarDescription.module.css";

const SliderResponses = ({ responses, handleLinkClick }) => {
  const totalPages = Math.ceil(responses.length / 2);
  const [currentPage, setCurrentPage] = useState(0);

  const handlePrevClick = () => {
    setCurrentPage((prevPage) =>
      prevPage === 0 ? totalPages - 1 : prevPage - 1
    );
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) =>
      prevPage === totalPages - 1 ? 0 : prevPage + 1
    );
  };

  // Обчислення індексів елементів для відображення на поточній сторінці
  const startIndex = currentPage * 2;
  const endIndex = startIndex + 2;
  const itemsToShow = responses.slice(startIndex, endIndex);

  return (
    <div className={styles.sliderContainer}>
      <button onClick={handlePrevClick} className={styles.arrowLeft}>
        <img src="/images/arrow_gray.png" alt="left" />
      </button>
      <div className={styles.slider}>
        {itemsToShow.map((item, index) => (
          <div key={index} className={styles.responseCard}>
            <div className={styles.userName}>
              <a
                href={`Profile?userId=${item.userID}`}
                className={styles.userUrl}
                onClick={(event) => handleLinkClick(event)}
              >
                <img
                  alt="profile"
                  src={
                    item.userAvatar ? item.userAvatar : "/images/profile1.png"
                  }
                  style={{ width: "30px" }}
                />{" "}
                {item.userName}
              </a>
            </div>
            <div className={styles.question}>{item.question}</div>
            <div className={styles.answer}>{item.answer}</div>
          </div>
        ))}
      </div>
      <button onClick={handleNextClick} className={styles.arrowRight}>
        <img src="/images/arrow_gray.png" alt="right" />
      </button>
    </div>
  );
};

export default SliderResponses;
