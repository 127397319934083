import React, { useState, useRef, useEffect } from "react";
import TextBlock from "../UI/TextBlock";
import styles from "./SellACar.module.css";
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";
import { carcoolReviews } from "../../data/sellACarData";
import { Link } from "react-router-dom";
import ScrollArrow from "../UI/ScrollArrow";

function carcoolReviewsAddKey(reviews) {
  return reviews.map((review, index) => ({
    ...review,
    key: index + 1,
  }));
}

const SellACar = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [showArrow, setShowArrow] = useState(true);
  const scrollRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const scrollToButton = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight } = document.documentElement;
      const buttonOffsetTop = scrollRef.current.offsetTop;
      setShowArrow(scrollTop < buttonOffsetTop - clientHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const items = carcoolReviewsAddKey(carcoolReviews);
  const slides = items.map((item, index) => {
    return (
      <CarouselItem
        className="custom-tag"
        tag="div"
        key={item.key}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <div className="d-flex justify-content-center">
          {item.users.map((user, i) => (
            <TextBlock
              key={`${index}-${i}`}
              color="#dbf6fa"
              className={
                i % 3 !== 2
                  ? styles.cardTextComent
                  : `me-0 ${styles.cardTextComent}`
              }
            >
              <img
                src="/images/5star.png"
                className={styles.imgIconStars}
                alt="Icon"
              />
              <p className={styles.pSellCar}>
                <strong>{user}</strong>
              </p>
              <p className={`mb-0 ${styles.pSellCar}`}>
                {item.testimonials[i]}
              </p>
            </TextBlock>
          ))}
        </div>
      </CarouselItem>
    );
  });

  return (
    <>
      <div className={styles.mwDiv}>
        <h1 className={styles.h1}>
          <strong>Продавай на</strong>{" "}
          <strong className={styles.blueText}>carcool!</strong>
          <strong>Більше грошей, менше клопоту.</strong>
        </h1>
        <TextBlock color="#fff" className="cardText">
          <h2 className={styles.h2}>
            <strong>Чому саме </strong>
            <strong className={styles.blueText}>Carcool?</strong>
          </h2>
          <ul className={styles.ul}>
            <li>
              <img
                src="/images/Message.png"
                className={styles.imgIcon}
                alt="Icon"
              />{" "}
              <strong>Онлайн підтримка</strong> від розміщення до продажу
            </li>
            <li>
              <img
                src="/images/Calendar_blue.png"
                className={styles.imgIcon}
                alt="Icon"
              />
              <strong>Продайте</strong> свій автомобіль за{" "}
              <strong>тиждень</strong> після публікації на аукціоні
            </li>
            <li>
              <img
                src="/images/community.png"
                className={styles.imgIcon}
                alt="Icon"
              />
              Отримайте доступ до нашої <strong>величезної</strong> аудиторії
              зацікавлених клієнтів
            </li>
            <li>
              <img
                src="/images/dollar.png"
                className={styles.imgIcon}
                alt="Icon"
              />
              Продавайте <strong>безкоштовно</strong> та отримуйте 100% від ціни
              продажу
            </li>
          </ul>
        </TextBlock>
        <TextBlock color="#fff" className={`cardText ${styles.minhCardText}`}>
          <h2 className={styles.h2}>
            <strong>Відгуки</strong>{" "}
            <strong className={styles.blueText}>Carcool!</strong>
          </h2>
          <Carousel
            activeIndex={activeIndex}
            next={next}
            previous={previous}
            ride="carousel"
          >
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
              className={styles.carouselIndicatorsSellACar}
            />
            {slides}
          </Carousel>
        </TextBlock>
        <div className={styles.containerLine}>
          <img
            src="/images/element1.png"
            style={{ width: "120%" }}
            alt="Дії для продажу"
          />
        </div>
        <div className={styles.button} ref={scrollRef}>
          <Link to="/ExhibitingACar" className={styles.sellCarLink}>
            <strong>Продати авто прямо зараз!</strong>
          </Link>
        </div>
      </div>
      <div className={styles.arrowScroll}>
        {showArrow && (
          <ScrollArrow arrowClass={styles.arrowDown} scroll={scrollToButton} />
        )}
      </div>
    </>
  );
};

export default SellACar;
