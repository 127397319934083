import React, { useEffect } from "react";
import { Toast, ToastHeader, ToastBody } from "reactstrap";

const UniversalToast = ({
  id,
  isOpen,
  header,
  body,
  toggle,
  icon,
  className,
}) => {
  useEffect(() => {
    let timer;
    if (isOpen) {
      timer = setTimeout(() => {
        toggle();
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [id, isOpen, toggle]);

  const handleToastClick = () => {
    toggle();
  };

  return (
    <Toast
      isOpen={isOpen}
      autohide="true"
      autohidetimeout={isOpen ? 0 : 5000}
      onClick={handleToastClick}
      className={className}
    >
      <ToastHeader icon={icon}><div dangerouslySetInnerHTML={{__html: header}}></div></ToastHeader>
      {body ? <ToastBody>{body}</ToastBody> : <></> }
    </Toast>
  );
};

export default UniversalToast;
