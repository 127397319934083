import CardDeteilsModeration from "./components/Moderation/CardDeteilsModeration";
import CardDeteils from "./components/Pages/CardDeteils";
import ExhibitingACar from "./components/Pages/ExhibitingACar";
import FAQ from "./components/Pages/FAQ";
import Home from "./components/Pages/Home";
import Authorize from "./components/Pages/Identification/Authorize";
import Registration from "./components/Pages/Identification/Registration";
import ResetPassword from "./components/Pages/Identification/ResetPassword";
import InModeration from "./components/Pages/InModeration";
import Profile from "./components/Pages/Profile";
import SellACar from "./components/Pages/SellACar";
import Announcements from "./components/ProfilePages/Announcements";
import Bids from "./components/ProfilePages/Bids";
import ChosenOnce from "./components/ProfilePages/ChosenOnes";
import Settings from "./components/ProfilePages/Settings";
import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/FAQ",
    element: <FAQ />,
  },
  {
    path: "/SellACar",
    element: <SellACar />,
  },
  {
    path: "/ExhibitingACar",
    element: (
      <ProtectedRoute>
        <ExhibitingACar />
      </ProtectedRoute>
    ),
  },
  {
    path: "/inModeration/:id",
    element: (
      <ProtectedRoute requiredRoles={["Moderator", "Admin"]}>
        <CardDeteilsModeration />
      </ProtectedRoute>
    ),
  },
  {
    path: "/inModeration",
    element: (
      <ProtectedRoute requiredRoles={["Moderator", "Admin"]}>
        <InModeration />
      </ProtectedRoute>
    ),
  },
  {
    path: "/CardDeteils/:id",
    element: <CardDeteils />,
  },
  {
    path: "/Profile",
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Profile />,
      },
      {
        path: "announcements",
        element: <Announcements />,
      },
      {
        path: "bids",
        element: <Bids />,
      },
      {
        path: "chosenonce",
        element: <ChosenOnce />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
    ],
  },
];

export const AuthRoutes = [
  {
    path: "/login",
    element: <Authorize />,
  },
  {
    path: "/registration",
    element: <Registration />,
  },
  {
    path: "/resetpassword",
    element: <ResetPassword />,
  },
];

export default AppRoutes;
