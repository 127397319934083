export const questionsBuyers = [
  {
    title: "Скільки коштує продати автомобіль на Carcool?",
    text: "text",
  },
  {
    title: "Як мені зареєструватися для участі в торгах?",
    text: "Щоб зареєструватися, спочатку зареєструйтеся, натиснувши піктограму «Увійти» у верхньому правому куті екрана, а потім натисніть «Зареєструватися тут» у вікні, яке з’явиться згодом. Після створення імені користувача та пароля вам буде запропоновано підтвердити адресу електронної пошти. Після того, як ви це зробите, ви можете повернутися до Carcool, і вам буде запропоновано зареєструватися для участі в ставках.<br/><br/> Якщо ви ще не хочете реєструватися, не біда – ви можете зробити це пізніше. Коли ви знайдете автомобіль, на який хочете зробити ставку, натисніть «Зробити ставку» на сторінці списку автомобіля, і вам буде запропоновано зареєструватися, перш ніж ви зможете зробити ставку. Вам доведеться ввести свою банківську карту та паспортні дані, оскільки ми заморожуємо частину коштів картки кожного учасника аукціону до завершення аукціону.",
  },
  {
    title: "Як зробити ставку?",
    text: "text",
  },
  {
    title: "Як працює збільшення ставок?",
    text: "text",
  },
];

export const questionSeller = [
  {
    title: "Скільки коштує продати автомобіль на Carcool?",
    text: "text",
  },
  {
    title: "Яку інформацію мені потрібно надати, щоб продати свій автомобіль?",
    text: "Ми починаємо з основ: ми можемо повідомити вам, чи приймемо ваш автомобіль, якщо ви просто надасте нам марку, модель, рік, VIN, кілька фотографій та кілька інших деталей. Якщо ми приймемо ваш автомобіль на основі цієї інформації, нам потрібно буде зібрати більше деталей – ми запитаємо про вашу історію з автомобілем, характеристики та інші елементи, які допомагають нам створити наш список.",
  },
  {
    title: "Як зробити найкращі фотографії мого автомобіля?",
    text: "text",
  },
  {
    id: 4,
    title: "Чи варто мені знімати відео?",
    text: "text",
  },
];

export const questionsAboutDelivery = [
  {
    title: "Чи можу я змінити або скасувати доставку після замовлення?",
    text: "text",
  },
  {
    title: "Як мені зареєструватися для участі в торгах?",
    text: "Щоб зареєструватися, спочатку зареєструйтеся, натиснувши піктограму «Увійти» у верхньому правому куті екрана, а потім натисніть «Зареєструватися тут» у вікні, яке з’явиться згодом. Після створення імені користувача та пароля вам буде запропоновано підтвердити адресу електронної пошти. Після того, як ви це зробите, ви можете повернутися до Carcool, і вам буде запропоновано зареєструватися для участі в ставках.<br/> <br/> Якщо ви ще не хочете реєструватися, не біда – ви можете зробити це пізніше. Коли ви знайдете автомобіль, на який хочете зробити ставку, натисніть «Зробити ставку» на сторінці списку автомобіля, і вам буде запропоновано зареєструватися, перш ніж ви зможете зробити ставку. Вам доведеться ввести свою банківську карту та паспортні дані, оскільки ми заморожуємо частину коштів картки кожного учасника аукціону до завершення аукціону.",
  },
  {
    title: "Чому вам потрібна моя повна адреса, щоб замовити доставку?",
    text: "text",
  },
  {
    title: "У мене проблема з доставкою, до кого мені звернутися?",
    text: "text",
  },
];

export const questionsRegistration = [
  {
    title: "Як я можу увійти?",
    text: "text",
  },
  {
    title: "Навіщо вам моя електронна адреса?",
    text: "text",
  },
  {
    title: "Що робити, якщо я забув пароль?",
    text: "Натисніть кнопку «Зареєструватися» або «Увійти» у верхньому правому куті екрана. Якщо ви бачите екран реєстрації, натисніть посилання «Увійти тут», щоб перейти до входу. У нижній частині екрана натисніть «Забули пароль?» посилання. Введіть адресу електронної пошти свого облікового запису, і ми надішлемо вам посилання для встановлення нового пароля. Переконайтеся, що у вас є доступ до облікового запису електронної пошти, оскільки це посилання діє через 24 години після того, як ми його вам надішлемо.",
  },
  {
    title: "Чи варто мені знімати відео?",
    text: "text",
  },
];
