import React from "react";
import Announcements from "./Announcements";
import Bids from "./Bids";
import ChosenOnce from "./ChosenOnes";
import Settings from "./Settings";
import ProfileView from "./ProfileView";

const ProfileContent = ({ currentPage, userId }) => {
  switch (currentPage) {
    case "announcements":
      return <Announcements />;
    case "bids":
      return <Bids />;
    case "chosenonce":
      return <ChosenOnce />;
    case "settings":
      return <Settings />;
    default:
      return <ProfileView userId={userId} />;
  }
};

export default ProfileContent;
