import React, { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Form,
  InputGroup,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavbarToggler,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import { useAuth } from "../Contexts/AuthContext";

const NavMenu = () => {
  const { isAuthenticated, user, logout } = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    await logout();
  };

  return (
    <header id="header">
      <Navbar
        className="navbar-expand-md container-fluid ng-white border-bottom box-shadow"
        light
      >
        <NavbarBrand tag={Link} to="/">
          <img src="/images/CARCOOL.png" className="logo" alt="Logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} />
        <Collapse isOpen={isOpen} navbar>
          {isAuthenticated() ? (
            <Dropdown
              nav
              isOpen={dropdownOpen}
              toggle={toggleDropdown}
              className=""
              style={{ listStyle: "none", order: "2" }}
            >
              <DropdownToggle nav caret={false} className="nav-autorize">
                <img
                  src="/images/profile1.png"
                  alt="user"
                  style={{ width: "40px" }}
                />{" "}
                {user.username}
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem tag={Link} to="/Profile">
                  Профіль
                </DropdownItem>
                {user.role === "Moderator" && (
                  <DropdownItem tag={Link} to="/inModeration">
                    Модерація
                  </DropdownItem>
                )}
                <DropdownItem onClick={handleLogout}>Вийти</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          ) : (
            <NavLink tag={Link} className="nav-autorize" to="/Login">
              Авторизація
            </NavLink>
          )}
          <div className="navbar-collapse">
            <div className="d-flex flex-column flex-md-row flex-grow-1">
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">
                    Аукціон
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark outlineNavLink"
                    to="/SellACar"
                  >
                    Продати Авто
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">
                    Про компанію
                  </NavLink>
                </NavItem>
              </ul>
              <Form className="d-flex search-form">
                <InputGroup className="inputGroupMainSearch">
                  <Button className="btn-search">
                    <img
                      src="/images/Magnifier_gray.png"
                      className="searchIcon"
                      alt="Icon"
                    />
                  </Button>
                  <Input
                    id="exampleSearch"
                    name="search"
                    placeholder="Пошук авто"
                    type="search"
                    className="searchInput"
                  />
                </InputGroup>
              </Form>
            </div>
          </div>
        </Collapse>
      </Navbar>
    </header>
  );
};

export default NavMenu;
