import React from 'react';
import styles from './TextInput.module.css'

const TextInput = ({ placeholder, onChange, value, isPassword }) => {
  const inputType = isPassword ? 'password' : 'text';

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <input className={styles.Input}
      type={inputType} 
      placeholder={placeholder}
      value={value || ''}
      onChange={handleChange}
    />
  );
}

export default TextInput;
