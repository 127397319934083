import React from "react";
import {
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import styles from "./AccordionList.module.css";

const AccordionList = ({ questions = [] }) => {
  return (
    <UncontrolledAccordion stayOpen>
      {questions.map((question) => {
        var bgColor =
          question.id % 2 !== 0 ? "blueBackGround" : "whiteBackGround";
        return (
          <AccordionItem key={question.id} className={bgColor}>
            <AccordionHeader
              targetId={question.id.toString()}
              className={bgColor}
            >
              <div className={styles.questionTitle}>
                {question.user && (
                  <div className={styles.user}>
                    <a
                      href={`Profile?userId=${question.userId}`}
                      className={styles.userUrl}
                    >
                      <img
                        className={styles.avatar}
                        alt="profile"
                        src={
                          question.avatar
                            ? question.avatar
                            : "/images/profile1.png"
                        }
                      />{" "}
                      {question.user}
                    </a>
                  </div>
                )}
                <strong>{question.title}</strong>
              </div>
            </AccordionHeader>
            <AccordionBody accordionId={question.id.toString()}>
              <p
                dangerouslySetInnerHTML={{ __html: question.text }}
                className={styles.questionText}
              ></p>
            </AccordionBody>
          </AccordionItem>
        );
      })}
    </UncontrolledAccordion>
  );
};

export default AccordionList;
