import React, { useState } from "react";
import Card from "./Card";
import styles from "../HomeComponents/CardList.module.css";
import NoLotsFound from "../UI/NoLotsFound";
import ButtonloadMore from "../UI/ButtonloadMore";

const ListCard = ({ cars }) => {
  const [visibleItems, setVisibleItems] = useState(100); // Початкова кількість видимих елементів

  const loadMore = () => {
    setVisibleItems((prev) => prev + 100); // Завантаження ще 100 елементів при кліку
  };
  return (
    <div
      className={`${styles.cardCarList} ${
        cars.length !== 0 ? "justify-content-start" : "justify-content-center"
      }`}
    >
      {cars.length !== 0 ? (
        cars
          .slice(0, visibleItems)
          .map((car, index) => (
            <Card
              key={index}
              id={car.lotID}
              title={`${car.productionYear} ${car.brand?.value} ${car.model?.value}`}
              text={`${car.body.value},${car.drive.value},${car.engine}, ${car.gearbox.value}, ${car.fuelType.value}, ${car.color}`}
              location={car.region.value}
              image={car.image}
            />
          ))
      ) : (
        <NoLotsFound />
      )}
      <div
        className={`d-flex justify-content-center align-items-center ${styles.visibleCardCar}`}
      >
        {cars.length > visibleItems && <ButtonloadMore load={loadMore} />}
      </div>
    </div>
  );
};

export default ListCard;
