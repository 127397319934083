import React from "react";
import styles from "./AuthButton.module.css";

const AuthButton = ({ type, children }) => {
  return (
    <button className={`${styles.CustomButton} mt-3 mb-3`} type={type}>
      {children}
    </button>
  );
};

export default AuthButton;
