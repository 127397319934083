import React, { useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import styles from "../Pages/ExhibitingACar.module.css";

const Question = ({ id, title, hint, stateElement, setStateElement }) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleShow = (value) => {
    setStateElement(value);
  };

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  return (
    <div>
      <h4>
        {title}{" "}
        <img
          src="/images/Question_mark.png"
          className={styles.imgQuestion}
          id={id}
          alt="Question"
          onMouseEnter={togglePopover}
          onMouseLeave={togglePopover}
        />
        <Popover
          placement="top"
          isOpen={popoverOpen}
          target={id}
          toggle={togglePopover}
        >
          <PopoverBody>{hint}</PopoverBody>
        </Popover>
        {typeof stateElement !== "undefined" && (
          <>
            <button
              type="button"
              className={
                stateElement ? styles.btnQuestionActive : styles.btnQuestion
              }
              onClick={() => handleShow(false)}
            >
              Ні
            </button>
            <button
              type="button"
              className={
                stateElement ? styles.btnQuestion : styles.btnQuestionActive
              }
              onClick={() => handleShow(true)}
            >
              Так
            </button>
          </>
        )}
      </h4>
    </div>
  );
};

export default Question;
