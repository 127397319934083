import axios from "axios";

const refreshUrl = `${process.env.REACT_APP_API_URL}/AccountManager/refresh-token`;

const AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: { 'Content-Type': 'application/json' }
  });

AxiosInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("CarCoolAccessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

AxiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const response = await axios.post(refreshUrl, {
          token: localStorage.getItem("CarCoolAccessToken"),
          refreshToken: localStorage.getItem("CarCoolRefreshToken"),
        });
        localStorage.setItem("CarCoolAccessToken", response.data.accessToken);
        localStorage.setItem("CarCoolRefreshToken", response.data.refreshToken);
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + response.data.accessToken;
        return AxiosInstance(originalRequest);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export default AxiosInstance;
