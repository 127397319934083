import React from "react";
import styles from "./Gallery.module.css";

const Image = ({ index, src, onClick, mainImage, service }) => {
  return (
    <div
      className={`${
        mainImage
          ? styles.mainImage
          : service
          ? styles.imageService
          : styles.image
      } ${index < 3 ? styles.right : ""}`}
      onClick={onClick}
    >
      <img
        style={mainImage && { width: "100%", height: "500px" }}
        src={src}
        alt="Gallery"
      />
    </div>
  );
};

export default Image;
