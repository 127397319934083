import React, { useEffect } from "react";
import { Input } from "reactstrap";
import styles from "../Pages/ExhibitingACar.module.css";

const TodoList = ({ placeholder, todos, setTodos }) => {
  useEffect(() => {
    if (todos.length === 0 || todos[todos.length - 1] !== "") {
      setTodos([...todos, ""]);
    }
  }, [todos, setTodos]);

  const handleInputChange = (index, value) => {
    const newTodos = [...todos];
    newTodos[index] = value;
    setTodos(newTodos);
    if (value === "" && index < newTodos.length - 1 && newTodos.length > 1) {
      const filteredTodos = newTodos.filter((_, i) => i !== index);
      setTodos(filteredTodos);
    }
  };

  return (
    <div>
      {todos.map((todo, index) => (
        <Input
          key={index}
          value={todo}
          onChange={(e) => handleInputChange(index, e.target.value)}
          placeholder={index === todos.length - 1 ? placeholder : ""}
          className={styles.input}
        />
      ))}
    </div>
  );
};

export default TodoList;
