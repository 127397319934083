export const dataAuthCarousel = [
  {
    src: "/images/signup-bg.png",
    altText: "Sign In",
    caption:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa eligendi expedita aliquam quaerat nulla voluptas facilis. Porro rem voluptates possimus, ad, autem quae culpa architecto, quam labore blanditiis at ratione.",
  },
  {
    src: "/images/signup-bg.png",
    altText: "Sign In",
    caption:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa eligendi expedita aliquam quaerat nulla voluptas facilis. Porro rem voluptates possimus, ad, autem quae culpa architecto, quam labore blanditiis at ratione.",
  },
  {
    src: "/images/signup-bg.png",
    altText: "Sign In",
    caption:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa eligendi expedita aliquam quaerat nulla voluptas facilis. Porro rem voluptates possimus, ad, autem quae culpa architecto, quam labore blanditiis at ratione.",
  },
];
