import React from "react";
import { useToast } from "../Contexts/ToastContext";
import TextBlock from "../UI/TextBlock";
import styles from "./ProfileContent.module.css";

const ProfileInformation = ({
  user,
  profileUser,
  newPhoto,
  setNewPhoto,
  profileText,
  newProfileText,
  setNewProfileText,
  handleSaveProfile,
  setIsChangingPhoto,
  setSelectedFile,
  isEditingProfile,
  setIsEditingProfile,
}) => {
  const { showToast } = useToast();

  const handleEditProfile = () => {
    setIsEditingProfile(true);
    setIsChangingPhoto(true);
    setNewProfileText(profileText);
  };

  const handleCancelEdit = () => {
    setIsEditingProfile(false);
    setIsChangingPhoto(false);
    setNewProfileText(profileText);
    setNewPhoto(profileUser.avatar || "/images/profile2.png");
    setSelectedFile(null);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      showToast(
        "Попередження",
        "Будь ласка, виберіть файл JPG або PNG.",
        "warning"
      );
    }
  };

  const formatDate = (dateString) => {
    const months = [
      "січня",
      "лютого",
      "березня",
      "квітня",
      "травня",
      "червня",
      "липня",
      "серпня",
      "вересня",
      "жовтня",
      "листопада",
      "грудня",
    ];

    const date = new Date(dateString);
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `з ${month} ${year}`;
  };

  return (
    <TextBlock color="#dbf6fa" className="cardText">
      <div className="row">
        <div className="col-xxl-3 col-xl-12 d-flex justify-content-center align-items-center">
          <div className="text-center">
            <img
              src={newPhoto ? newPhoto : "/images/profile2.png"}
              alt="profile"
              className={styles.imgProfile}
            />
            {profileUser.userId === user.ID && isEditingProfile && (
              <>
                <label htmlFor="fileInput" className={styles.btnProfile}>
                  <strong>Змінити фото</strong>
                </label>
                <input
                  id="fileInput"
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </>
            )}
            {profileUser.userId === user.ID && !isEditingProfile && (
              <button onClick={handleEditProfile} className={styles.btnProfile}>
                <strong>Змінити профіль</strong>
              </button>
            )}
          </div>
        </div>
        <div className={`col-xxl-9 col-xl-12 ${styles.ptProfile}`}>
          <h2>
            <strong>{profileUser.username}</strong>
          </h2>
          <h2>
            <strong>
              {profileUser.firstName} {profileUser.surname}
            </strong>
          </h2>
          <h5 className="mb-4">
            на CARCOOL {formatDate(profileUser.dateRegister)}
          </h5>
          {profileUser.userId === user.ID && isEditingProfile ? (
            <>
              <textarea
                className={styles.textArea}
                value={newProfileText}
                onChange={(e) => setNewProfileText(e.target.value)}
              />
              <div
                className={`d-flex justify-content-end align-items-end ${styles.buttonContainer}`}
              >
                <button
                  onClick={handleSaveProfile}
                  className={styles.btnProfile}
                >
                  <strong>Зберегти</strong>
                </button>
                <button
                  onClick={handleCancelEdit}
                  className={styles.btnProfile}
                  style={{ marginLeft: "20px" }}
                >
                  <strong>Відмінити</strong>
                </button>
              </div>
            </>
          ) : (
            <p className={styles.textProfileUser}>{profileText}</p>
          )}
        </div>
      </div>
    </TextBlock>
  );
};

export default ProfileInformation;
