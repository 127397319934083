import React, { useState, useEffect, useCallback } from "react";
import { UncontrolledAccordion, Input } from "reactstrap";
import Select from "react-select";
import TextBlock from "../UI/TextBlock";
import AccordionItem from "../UI/AccordionItem";
import styles from "./Filters.module.css";
import useFetchData from "../Hooks/useFetchData";

//Select custom style
const customStyles = {
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? "var(--dark-gray)" : "var(--dark-gray)",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "var(--dark-gray)",
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? "var(--primary-blue)" : "var(--light-gray)",
    color: state.isSelected ? "white" : "var(--text-color)",
    "&:hover": {
      background: "#0097b26b",
      color: "white",
    },
  }),
  control: (provided, state) => ({
    ...provided,
    minWidth: "100px",
    fontSize: "15px",
    border: "2px solid var(--primary-blue)",
    borderColor: state.isFocused
      ? "var(--primary-blue)"
      : "var(--primary-blue)",
    background: "var(--light-gray)",
    borderRadius: "14px",
    boxShadow: state.isFocused ? "none" : "none",
    "&:hover": {
      borderColor: "var(--primary-blue)",
    },
  }),
};

const transformObject = (data) => {
  if (!data || typeof data !== "object") {
    return [];
  }
  return Object.entries(data).map(([key, value]) => ({
    value: key,
    label: value,
  }));
};

const transformObjectModels = (data) => {
  if (!data || typeof data !== "object") {
    return {};
  }

  const brands = Object.keys(data);
  const transformedData = {};

  brands.forEach((brand) => {
    if (typeof data[brand] === "object") {
      transformedData[brand] = Object.entries(data[brand]).map(
        ([key, value]) => ({ value: key, label: value })
      );
    }
  });

  return transformedData;
};

const Filters = ({
  onFilterResize,
  applyFilters,
  selectedFilters,
  setSelectedFilters,
}) => {
  const [activeAccordions, setActiveAccordions] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [searchBrand, setSearchBrand] = useState("");
  const [searchModel, setSearchModel] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [searchTransmissionType, setSearchTransmissionType] = useState("");
  const [searchFuelType, setSearchFuelType] = useState("");
  const [searchDriveType, setSearchDriveType] = useState("");
  const [searchCarType, setSearchCarType] = useState("");
  const [showAllRegions, setShowAllRegions] = useState(false);
  const [searchRegion, setSearchRegion] = useState("");

  //Фільтри
  const [yearsOptions, setYearsOptions] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [driveTypes, setDriveTypes] = useState([]);
  const [transmissionTypes, setTransmissionTypes] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [regions, setRegions] = useState([]);
  const [brands, setBrands] = useState([]);
  const [modelsByBrand, setModelsByBrand] = useState({});

  const { fetchData } = useFetchData("Filters/AuctionFilters");

  useEffect(() => {
    fetchData()
      .then((data) => {
        const transformedRegions = transformObject(data.regions);
        const transformedBodyTypes = transformObject(data.bodyTypes);
        const transformedDriveTypes = transformObject(data.drives);
        const transformedTransmissionTypes = transformObject(
          data.transmissions
        );
        const transformedFuelTypes = transformObject(data.fuels);
        const transformedModels = transformObjectModels(data.modelsByBrand);
        setYearsOptions(data.years);
        setBodyTypes(transformedBodyTypes);
        setDriveTypes(transformedDriveTypes);
        setTransmissionTypes(transformedTransmissionTypes);
        setFuelTypes(transformedFuelTypes);
        setRegions(transformedRegions);
        setBrands(data.brands);
        setModelsByBrand(transformedModels);
      })
      .catch((err) =>
        console.error("There was a problem fetching the car data:", err)
      );
  }, [fetchData]);

  //Передаємо стани фільтрів
  const handleFilterChange = useCallback(() => {
    if (selectedFilters) {
      applyFilters(selectedFilters);
    }
  }, [selectedFilters, applyFilters]);

  useEffect(() => {
    handleFilterChange();
  }, [handleFilterChange]);

  const handleRegionChange = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      selectedRegions: checked
        ? [...prevFilters.selectedRegions, value]
        : prevFilters.selectedRegions.filter((region) => region !== value),
    }));
  };

  const handleToggleShowAllRegions = () => {
    setShowAllRegions(!showAllRegions);
  };
  const handleSearchRegion = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchRegion(searchTerm);
    setShowAll(false);
  };

  const filteredRegions = regions.filter((region) =>
    region.label.toLowerCase().includes(searchRegion)
  );
  const visibleRegions = showAllRegions
    ? filteredRegions
    : filteredRegions.slice(0, 8);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleBrandChange = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (checked) {
        // Очищаємо вибрані моделі для попередньої марки
        updatedFilters.selectedModels[value] = [];
        updatedFilters.selectedBrands.push(value);
      } else {
        // Видаляємо вибрану марку та відповідні моделі
        delete updatedFilters.selectedModels[value];
        updatedFilters.selectedBrands = updatedFilters.selectedBrands.filter(
          (brand) => brand !== value
        );
      }
      return updatedFilters;
    });
  };

  const handleModelChange = (brand, event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (!updatedFilters.selectedModels[brand]) {
        updatedFilters.selectedModels[brand] = [];
      }
      if (checked) {
        updatedFilters.selectedModels[brand].push(value);
      } else {
        updatedFilters.selectedModels[brand] = updatedFilters.selectedModels[
          brand
        ].filter((model) => model !== value);
      }
      return updatedFilters;
    });
  };

  const filteredVisibleBrands = brands
    .filter((brand) =>
      brand.value.toLowerCase().includes(searchBrand.toLowerCase())
    )
    .slice(0, showAll ? undefined : 8);

  const filteredModels = selectedFilters.selectedBrands.reduce((acc, brand) => {
    const filteredModelsByBrand = modelsByBrand[brand]?.filter((model) =>
      model.value.toLowerCase().includes(searchModel.toLowerCase())
    );
    if (filteredModelsByBrand?.length > 0) {
      acc[brand] = filteredModelsByBrand;
    }
    return acc;
  }, {});

  const handleCarTypeChange = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (checked) {
        updatedFilters.selectedCarTypes = [
          ...updatedFilters.selectedCarTypes,
          value,
        ];
      } else {
        updatedFilters.selectedCarTypes =
          updatedFilters.selectedCarTypes.filter((type) => type !== value);
      }
      return updatedFilters;
    });
  };

  const filteredCarTypes = bodyTypes.filter((carType) =>
    carType.label.toLowerCase().includes(searchCarType.toLowerCase())
  );

  const handleTransmissionTypeChange = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (checked) {
        updatedFilters.selectedTransmissionTypes = [
          ...updatedFilters.selectedTransmissionTypes,
          value,
        ];
      } else {
        updatedFilters.selectedTransmissionTypes =
          updatedFilters.selectedTransmissionTypes.filter(
            (type) => type !== value
          );
      }
      return updatedFilters;
    });
  };

  const filteredTransmissionTypes = transmissionTypes.filter(
    (transmissionType) =>
      transmissionType.label
        .toLowerCase()
        .includes(searchTransmissionType.toLowerCase())
  );

  const handleFuelTypeChange = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (checked) {
        updatedFilters.selectedFuelTypes = [
          ...updatedFilters.selectedFuelTypes,
          value,
        ];
      } else {
        updatedFilters.selectedFuelTypes =
          updatedFilters.selectedFuelTypes.filter((type) => type !== value);
      }
      return updatedFilters;
    });
  };

  const filteredFuelTypes = fuelTypes.filter((fuelType) =>
    fuelType.label.toLowerCase().includes(searchFuelType.toLowerCase())
  );

  const handleDriveTypeChange = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (checked) {
        updatedFilters.selectedDriveTypes = [
          ...updatedFilters.selectedDriveTypes,
          value,
        ];
      } else {
        updatedFilters.selectedDriveTypes =
          updatedFilters.selectedDriveTypes.filter((type) => type !== value);
      }
      return updatedFilters;
    });
  };

  const filteredDriveTypes = driveTypes.filter((driveType) =>
    driveType.label.toLowerCase().includes(searchDriveType.toLowerCase())
  );

  const handleInputChange = (e, setValueFunc) => {
    const value = e.target.value.replace(/\D/g, "");
    setValueFunc(value);
  };

  const handleMileageFromChange = (e) => {
    handleInputChange(e, (value) =>
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        mileageFrom: value,
      }))
    );
  };

  const handleMileageToChange = (e) => {
    handleInputChange(e, (value) =>
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        mileageTo: value,
      }))
    );
  };

  const handlePriceFromChange = (e) => {
    handleInputChange(e, (value) =>
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        priceFrom: value,
      }))
    );
  };

  const handlePriceToChange = (e) => {
    handleInputChange(e, (value) =>
      setSelectedFilters((prevFilters) => ({
        ...prevFilters,
        priceTo: value,
      }))
    );
  };

  const handleYearFromChange = (selectedValue) => {
    setSelectedFilters((prevFilters) => {
      if (
        !prevFilters.selectedYearTo ||
        selectedValue.value <= prevFilters.selectedYearTo.value
      ) {
        return {
          ...prevFilters,
          selectedYearFrom: selectedValue,
          selectedYearTo:
            prevFilters.selectedYearTo &&
            selectedValue.value > prevFilters.selectedYearTo.value
              ? selectedValue
              : prevFilters.selectedYearTo,
        };
      } else {
        return {
          ...prevFilters,
          selectedYearFrom: prevFilters.selectedYearTo,
          selectedYearTo: selectedValue,
        };
      }
    });
  };

  const handleYearToChange = (selectedValue) => {
    setSelectedFilters((prevFilters) => {
      if (
        !prevFilters.selectedYearFrom ||
        selectedValue.value >= prevFilters.selectedYearFrom.value
      ) {
        return {
          ...prevFilters,
          selectedYearTo: selectedValue,
          selectedYearFrom:
            prevFilters.selectedYearFrom &&
            selectedValue.value < prevFilters.selectedYearFrom.value
              ? selectedValue
              : prevFilters.selectedYearFrom,
        };
      } else {
        return {
          ...prevFilters,
          selectedYearTo: prevFilters.selectedYearFrom,
          selectedYearFrom: selectedValue,
        };
      }
    });
  };

  //Acardion function
  const handleAccordionClick = (accordionId) => {
    setActiveAccordions((prevState) => {
      if (prevState.includes(accordionId)) {
        return prevState.filter((id) => id !== accordionId);
      } else {
        return [...prevState, accordionId];
      }
    });
  };

  const toggleCollapsed = () => {
    const anyAccordionOpen = activeAccordions.length > 0;
    const isAlreadyCollapsed = !collapsed;

    if (anyAccordionOpen && isAlreadyCollapsed) {
      setActiveAccordions([]);
    }
    setCollapsed(!collapsed);
    const newWidth = collapsed ? "25%" : "90px";
    onFilterResize(newWidth);
  };

  const handleKeyDown = (e) => {
    if (!/^\d$/.test(e.key) && e.key !== "Backspace") {
      e.preventDefault();
    }
  };

  return (
    <div className={styles.filters}>
      <div className={styles.toggleButton} onClick={toggleCollapsed}>
        <img
          src="/images/arrow_gray.png"
          className={collapsed ? styles.RightIcon : styles.LeftIcon}
          alt="Toggle icon"
        />
      </div>
      {collapsed === false ? (
        <>
          <TextBlock color="#ffffff" className={styles.filtersCar}>
            <div style={{ paddingLeft: "20px" }}>
              <img
                alt="filter"
                src="/images/filter.png"
                className={styles.bigFiltersImg}
              />{" "}
              Фільтри
            </div>

            <UncontrolledAccordion stayOpen>
              <AccordionItem
                id="1"
                title="Марка"
                isActive={activeAccordions.includes("1")}
                onToggle={handleAccordionClick}
              >
                <Input
                  type="text"
                  value={searchBrand}
                  onChange={(e) => setSearchBrand(e.target.value)}
                  placeholder="Пошук по марці"
                  className="custom-input-filter mb-2"
                />
                {filteredVisibleBrands.map((brand) => (
                  <div key={brand.value} className="px-1 py-1">
                    <Input
                      type="checkbox"
                      id={brand.value}
                      value={brand.value}
                      checked={selectedFilters.selectedBrands.includes(
                        brand.value
                      )}
                      onChange={handleBrandChange}
                      className={`${styles.customInputFilter} mb-2`}
                    />
                    <label htmlFor={brand.value}>{brand.label}</label>
                  </div>
                ))}
                {brands.length > 8 && (
                  <button onClick={toggleShowAll} className={styles.btnToggle}>
                    {showAll ? "Звернути" : "Показати більше"}
                  </button>
                )}
              </AccordionItem>
              <AccordionItem
                id="2"
                title="Модель"
                isActive={activeAccordions.includes("2")}
                onToggle={handleAccordionClick}
              >
                {selectedFilters.selectedBrands.length === 0 ? (
                  <p>Виберіть спочатку марку</p>
                ) : (
                  <div>
                    <Input
                      type="text"
                      value={searchModel}
                      onChange={(e) => setSearchModel(e.target.value)}
                      placeholder="Пошук по моделі"
                      className="custom-input-filter mb-2"
                    />
                    {selectedFilters.selectedBrands.map((brand) => (
                      <div key={brand}>
                        <h5 className={`text-center ${styles.titleModel}`}>
                          {brand.toUpperCase()}
                        </h5>
                        {filteredModels[brand]?.map((model) => (
                          <div key={model.value} className="px-1 py-1">
                            <Input
                              type="checkbox"
                              id={model.value}
                              value={model.value}
                              checked={selectedFilters.selectedModels[
                                brand
                              ]?.includes(model.value)}
                              onChange={(e) => handleModelChange(brand, e)}
                              className={`${styles.customInputFilter} mb-2`}
                            />
                            <label htmlFor={model.value}>{model.label}</label>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                )}
              </AccordionItem>
              <AccordionItem
                id="3"
                title="Рік"
                isActive={activeAccordions.includes("3")}
                onToggle={handleAccordionClick}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <Select
                    options={yearsOptions}
                    value={selectedFilters.selectedYearFrom}
                    onChange={handleYearFromChange}
                    placeholder="Від"
                    onKeyDown={handleKeyDown}
                    styles={{
                      ...customStyles,
                    }}
                  />
                  <span className={styles.spanDash}>—</span>
                  <Select
                    options={yearsOptions}
                    value={selectedFilters.selectedYearTo}
                    onChange={handleYearToChange}
                    placeholder="До"
                    onKeyDown={handleKeyDown}
                    styles={{
                      ...customStyles,
                    }}
                  />
                </div>
              </AccordionItem>
              <AccordionItem
                id="4"
                title="Тип кузова"
                isActive={activeAccordions.includes("4")}
                onToggle={handleAccordionClick}
              >
                <Input
                  type="text"
                  value={searchCarType}
                  onChange={(e) => setSearchCarType(e.target.value)}
                  placeholder="Пошук кузова"
                  className="custom-input-filter mb-2"
                />
                {filteredCarTypes.map((carType) => (
                  <div key={carType.value} className="px-1 py-1">
                    <Input
                      type="checkbox"
                      id={carType.value}
                      value={carType.value}
                      checked={selectedFilters.selectedCarTypes.includes(
                        carType.value
                      )}
                      onChange={handleCarTypeChange}
                      className={`${styles.customInputFilter} mb-2`}
                    />
                    <label htmlFor={carType.value}>{carType.label}</label>
                  </div>
                ))}
              </AccordionItem>
              <AccordionItem
                id="5"
                title="Тип КПП"
                isActive={activeAccordions.includes("5")}
                onToggle={handleAccordionClick}
              >
                <Input
                  type="text"
                  value={searchTransmissionType}
                  onChange={(e) => setSearchTransmissionType(e.target.value)}
                  placeholder="Пошук типу КПП"
                  className="custom-input-filter mb-2"
                />
                {filteredTransmissionTypes.map((transmissionType) => (
                  <div key={transmissionType.value} className="px-1 py-1">
                    <Input
                      type="checkbox"
                      id={transmissionType.value}
                      value={transmissionType.value}
                      checked={selectedFilters.selectedTransmissionTypes.includes(
                        transmissionType.value
                      )}
                      onChange={handleTransmissionTypeChange}
                      className={`${styles.customInputFilter} mb-2`}
                    />
                    <label htmlFor={transmissionType.value}>
                      {transmissionType.label}
                    </label>
                  </div>
                ))}
              </AccordionItem>
              <AccordionItem
                id="6"
                title="Тип приводу"
                isActive={activeAccordions.includes("6")}
                onToggle={handleAccordionClick}
              >
                <Input
                  type="text"
                  value={searchDriveType}
                  onChange={(e) => setSearchDriveType(e.target.value)}
                  placeholder="Пошук приводу"
                  className="custom-input-filter mb-2"
                />
                {filteredDriveTypes.map((driveType) => (
                  <div key={driveType.value} className="px-1 py-1">
                    <Input
                      type="checkbox"
                      id={driveType.value}
                      value={driveType.value}
                      checked={selectedFilters.selectedDriveTypes.includes(
                        driveType.value
                      )}
                      onChange={handleDriveTypeChange}
                      className={`${styles.customInputFilter} mb-2`}
                    />
                    <label htmlFor={driveType.value}>{driveType.label}</label>
                  </div>
                ))}
              </AccordionItem>
              <AccordionItem
                id="7"
                title="Тип палива"
                isActive={activeAccordions.includes("7")}
                onToggle={handleAccordionClick}
              >
                <Input
                  type="text"
                  value={searchFuelType}
                  onChange={(e) => setSearchFuelType(e.target.value)}
                  placeholder="Пошук типу палива"
                  className="custom-input-filter mb-2"
                />
                {filteredFuelTypes.map((fuelType) => (
                  <div key={fuelType.value} className="px-1 py-1">
                    <Input
                      type="checkbox"
                      id={fuelType.value}
                      value={fuelType.value}
                      checked={selectedFilters.selectedFuelTypes.includes(
                        fuelType.value
                      )}
                      onChange={handleFuelTypeChange}
                      className={`${styles.customInputFilter} mb-2`}
                    />
                    <label htmlFor={fuelType.value}>{fuelType.label}</label>
                  </div>
                ))}
              </AccordionItem>
              <AccordionItem
                id="8"
                title="Пробіг (тис. км.)"
                isActive={activeAccordions.includes("8")}
                onToggle={handleAccordionClick}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <Input
                    value={selectedFilters.mileageFrom}
                    onChange={handleMileageFromChange}
                    onKeyDown={handleKeyDown}
                    className="custom-input-filter"
                    style={{ width: "50%" }}
                    placeholder="Від"
                  />
                  <span className={styles.spanDash}>—</span>
                  <Input
                    value={selectedFilters.mileageTo}
                    onChange={handleMileageToChange}
                    onKeyDown={handleKeyDown}
                    className="custom-input-filter"
                    style={{ width: "50%" }}
                    placeholder="до"
                  />
                </div>
              </AccordionItem>
              <AccordionItem
                id="9"
                title="Ціна"
                isActive={activeAccordions.includes("9")}
                onToggle={handleAccordionClick}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <Input
                    type="text"
                    value={selectedFilters.priceFrom}
                    onChange={handlePriceFromChange}
                    onKeyDown={handleKeyDown}
                    className="custom-input-filter"
                    placeholder="Від"
                    style={{ width: "50%" }}
                  />
                  <span className={styles.spanDash}>—</span>
                  <Input
                    type="text"
                    value={selectedFilters.priceTo}
                    onChange={handlePriceToChange}
                    onKeyDown={handleKeyDown}
                    className="custom-input-filter"
                    placeholder="до"
                    style={{ width: "50%" }}
                  />
                </div>
              </AccordionItem>
              <AccordionItem
                id="10"
                title="Область"
                isActive={activeAccordions.includes("10")}
                onToggle={handleAccordionClick}
              >
                <Input
                  type="text"
                  value={searchRegion}
                  onChange={handleSearchRegion}
                  placeholder="Пошук по області"
                  className="custom-input-filter mb-2"
                />
                {visibleRegions.map((region) => (
                  <div key={region.value} className="px-1 py-1">
                    <Input
                      type="checkbox"
                      id={region.value}
                      value={region.value}
                      checked={selectedFilters.selectedRegions.includes(
                        region.value
                      )}
                      onChange={handleRegionChange}
                      className={`${styles.customInputFilter} mb-2`}
                    />
                    <label htmlFor={region.value}>{region.label}</label>
                  </div>
                ))}
                {regions.length > 8 && (
                  <button
                    onClick={handleToggleShowAllRegions}
                    className={styles.btnToggle}
                  >
                    {showAllRegions ? "Звернути" : "Показати більше"}
                  </button>
                )}
              </AccordionItem>
            </UncontrolledAccordion>
          </TextBlock>
        </>
      ) : (
        <>
          <TextBlock color="#ffffff" className={styles.filtersCar}>
            <img
              alt="filter"
              src="/images/filter.png"
              className={styles.shortFilterImg}
            />
          </TextBlock>
        </>
      )}
    </div>
  );
};

export default Filters;
