import React, { useState, useEffect } from "react";

const formatTime = (hours, minutes, seconds) => {
  const pad = (value) => {
    return value < 10 ? "0" + value : value;
  };

  return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
};

const Timer = ({ endDateTime, changeStyle, onTimerEnd }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(endDateTime) - +new Date();
    if (difference <= 0) {
      return "00:00:00";
    }
    const hours = Math.floor(difference / 3600 / 1000);
    const minutes = Math.floor((difference % (3600 * 1000)) / (60 * 1000));
    const seconds = Math.floor((difference % (60 * 1000)) / 1000);

    return formatTime(hours, minutes, seconds);
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
      if (newTimeLeft === "00:00:00") {
        changeStyle("var(--dark-gray)");
        if (onTimerEnd) onTimerEnd();
      } else if (parseInt(newTimeLeft.split(":")[0]) < 1) {
        changeStyle("var(--bs-red)");
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <>
      {timeLeft && timeLeft !== "00:00:00" ? timeLeft : <span>Час минув!</span>}
    </>
  );
};

export default Timer;
