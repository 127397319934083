import React from "react";
import {
  AccordionItem as RsAccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import styles from "../HomeComponents/Filters.module.css";

const AccordionItem = ({ id, title, isActive, onToggle, children }) => {
  return (
    <RsAccordionItem>
      <AccordionHeader
        targetId={id}
        onClick={() => onToggle(id)}
        className={isActive ? styles.activeAccordionHeader : ""}
      >
        {title}
      </AccordionHeader>
      <AccordionBody
        accordionId={id}
        className={isActive ? styles.activeAccordionBody : ""}
      >
        {children}
      </AccordionBody>
    </RsAccordionItem>
  );
};

export default AccordionItem;
