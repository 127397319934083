import * as signalR from "@microsoft/signalr";

const url = `${process.env.REACT_APP_API_URL}/chatHub`;

const createSignalRConnectionForChat = (lotId) => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => localStorage.getItem("token"),
    })
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection
    .start()
    .then(() => {
      connection
        .send("JoinGroup", lotId)
        .catch((err) =>
          console.error(`Failed to join group CHAT ${lotId}: `, err)
        );
    })
    .catch((err) =>
      console.error(`SignalR Connection Error CHAT ${lotId}: `, err)
    );

  return connection;
};

export default createSignalRConnectionForChat;
