import React, { useState } from "react";
import CardCar from "../UI/CardCar";
import styles from "./CardList.module.css";
import NoLotsFound from "../UI/NoLotsFound";
import ButtonloadMore from "../UI/ButtonloadMore";

const CardList = ({ filteredCards, toggleFavorite }) => {
  const [visibleItems, setVisibleItems] = useState(99); // Початкова кількість видимих елементів

  const loadMore = () => {
    setVisibleItems((prev) => prev + 99); // Завантаження ще 100 елементів при кліку
  };
  return (
    <div
      className={`${styles.cardCarList} ${
        filteredCards.length !== 0
          ? "justify-content-start"
          : "justify-content-center"
      }`}
    >
      {filteredCards.length !== 0 ? (
        filteredCards
          .slice(0, visibleItems)
          .map((car, index) => (
            <CardCar
              key={car.lotID}
              id={car.lotID}
              index={`Lot_${index}`}
              title={`${car.productionYear} ${car.brand?.value} ${car.model?.value}`}
              text={`${car.body.value},${car.drive.value},${car.engine}, ${car.gearbox.value}, ${car.fuelType.value}, ${car.color}`}
              location={car.region.value}
              price={car.price}
              reserv={car.hasReservePrice}
              reservPrice={car.reservePrice}
              checked={car.checked}
              favoritesCar={car.favorite}
              image={car.image}
              toggleFavorite={toggleFavorite}
              endTime={car.timerEndTime}
            />
          ))
      ) : (
        <NoLotsFound />
      )}
      <div
        className={`d-flex justify-content-center align-items-center ${styles.visibleCardCar}`}
      >
        {filteredCards.length > visibleItems && (
          <ButtonloadMore load={loadMore} />
        )}
      </div>
    </div>
  );
};

export default CardList;
