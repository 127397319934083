import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";
import styles from "../UI/CardCar.module.css";

const CardCar = ({ id, image, title, text, location: loc }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/inModeration/${id}`);
  };
  return (
    <Card
      className={styles.cardCar}
      onClick={handleClick}
      style={{ cursor: "pointer" }}
    >
      <img alt="Sample" src={image} className={styles.cardImg} />
      <CardBody className={styles.cardBody}>
        <div className="d-flex justify-content-between align-items-center">
          <CardTitle tag="h5" className={styles.cardCarTitle}>
            {title}
          </CardTitle>
        </div>
        <CardText className={styles.cardCarText}>{text}</CardText>
        <CardText className={styles.cardCarTextLocation}>
          <img
            alt="Obl"
            src="/images/point.png"
            className={styles.imgLocation}
          />
          {loc}
        </CardText>
      </CardBody>
    </Card>
  );
};

export default CardCar;
