import React from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import styles from "./NavProfile.module.css";
import TextBlock from "../UI/TextBlock";

const NavProfile = () => {
  return (
    <TextBlock color="#dbf6fa" className={`cardText ${styles.navProfile}`}>
      <ul className="d-flex justify-content-between flex-wrap">
        <li>
          <NavLink tag={Link} to=".">
            <strong>Профіль</strong>
          </NavLink>
        </li>
        <li>
          <NavLink tag={Link} to="announcements">
            <strong>Мої оголошення</strong>
          </NavLink>
        </li>
        <li>
          <NavLink tag={Link} to="bids">
            <strong>Мої ставки</strong>
          </NavLink>
        </li>
        <li>
          <NavLink tag={Link} to="chosenonce">
            <strong>Мої обрані</strong>
          </NavLink>
        </li>
        <li>
          <NavLink tag={Link} to="settings">
            <strong>Налаштування</strong>
          </NavLink>
        </li>
      </ul>
    </TextBlock>
  );
};

export default NavProfile;
