import React from "react";
import styles from "../Moderation/CardDeteilsModeration.module.css";

const ButtonEditModeration = ({ handleClick }) => {
  return (
    <button onClick={handleClick} className={styles.buttonEdit}>
      <img alt="edit" src="/images/edit_1.png" className={styles.img} />
      <strong>Редагувати</strong>
    </button>
  );
};

export default ButtonEditModeration;
