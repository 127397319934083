const ConfirmRegistration = async (registrationModel, apiUrl) => {
  const registrationUrl = `${apiUrl}/AccountManager/registration`;
  let responseData = null;
  let error = null;

  try {
    const response = await fetch(registrationUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registrationModel),
    });

    if (!response.ok) {
      error = await response.text();
    } else {
      responseData = await response.json();
    }
  } catch (err) {
    error = err.message;
  }
  return { responseData, error };
};

const Login = async (signInModel, apiUrl) => {
  const signInUrl = `${apiUrl}/AccountManager/login`;
  let responseData = null;
  let error = null;

  try {
    const response = await fetch(signInUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(signInModel),
    });

    if (!response.ok) {
      error = await response.text();
    } else {
      responseData = await response.json();
    }
  } catch (err) {
    error = err.message;
  }
  return { responseData, error };
};

const ResetPassword = async (email, apiUrl) => {
  const signInUrl = `${apiUrl}/AccountManager/forget-password`;
  let responseData = null;
  let error = null;

  try {
    const response = await fetch(signInUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(email),
    });

    if (!response.ok) {
      error = await response.text();
    } else {
      responseData = await response.json();
    }
  } catch (err) {
    error = err.message;
  }
  return { responseData, error };
};

export { ConfirmRegistration, Login, ResetPassword };
