import React from "react";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";
import styles from "../Pages/ExhibitingACar.module.css";

const InputFormGroup = ({
  label,
  value,
  onChange,
  error,
  errorMessage,
  formSubmitted,
  ...rest
}) => {
  const handleInputChange = (event) => {
    onChange(event.target.value);
  };

  const showError =
    formSubmitted &&
    (error || value === "") &&
    errorMessage !== "" &&
    errorMessage !== undefined;

  return (
    <FormGroup>
      <Label className={styles.labelExhibitingACar}>
        {label} <span className={styles.spanStar}>*</span>
      </Label>
      <Input
        className={styles.input}
        value={value}
        onChange={handleInputChange}
        {...rest}
        invalid={showError}
      />
      <FormFeedback
        style={{
          display: showError ? "block" : "none",
        }}
      >
        {errorMessage}
      </FormFeedback>
    </FormGroup>
  );
};

export default InputFormGroup;
