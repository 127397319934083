import React from "react";
import UserCars from "../UI/UserCars";

const Bids = () => {
  return (
    <UserCars
      urlController={"Profile/GetListBid?userId="}
      title={`Мої ставки `}
    />
  );
};

export default Bids;