import React, { useState } from "react";
import TextBlock from "./TextBlock";
import GalleryServiceHistory from "./GalleryServiceHistory";
import SliderResponses from "./SliderResponses";
import ModalAllOwnerResponses from "./ModalAllOwnerResponses";
import styles from "./CarDescription.module.css";

const CarDescription = ({ car, handleLinkClick }) => {
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);

  const toggleResponseModal = () =>
    setIsResponseModalOpen(!isResponseModalOpen);

  const completeSetOfCars = car.completeSetOfCars || [];
  const modifiedList = car.modifiedList || [];
  const disadvantagesList = car.disadvantagesList || [];

  const hasServiceHistory =
    car.serviceHistoryDescription ||
    (car.serviceHistoryImages && car.serviceHistoryImages.length > 0);

  return (
    <>
      {car.carDescription && (
        <TextBlock color="#dbf6fa" className="cardText">
          <h3 style={{ margin: "0 0 15px 0" }}>
            <strong>Опис від продавця</strong>{" "}
            <a
              href={car.ownerID ? `Profile?userId=${car.ownerID}` : "#"}
              className={styles.ownerUrl}
              onClick={(event) => handleLinkClick(event)}
            >
              <img
                alt="profile"
                src="/images/profile1.png"
                style={{ width: "40px" }}
              />{" "}
              <strong className={styles.ownerNameUrl}>{car.ownerName}</strong>
            </a>
          </h3>
          <p className={styles.font18}>{car.carDescription}</p>
        </TextBlock>
      )}
      {completeSetOfCars.length > 0 && (
        <TextBlock
          color={car.carDescription ? "#fff" : "#dbf6fa"}
          className="cardText"
        >
          <h3 style={{ margin: "0 0 15px 0" }}>
            <strong>Комплектація</strong>
          </h3>
          <ul className={styles.font18}>
            {completeSetOfCars.map((complectation, index) => (
              <li key={index}>{complectation}</li>
            ))}
          </ul>
        </TextBlock>
      )}
      {modifiedList.length > 0 && (
        <TextBlock
          color={completeSetOfCars.length > 0 ? "#dbf6fa" : "#fff"}
          className="cardText"
        >
          <h3 style={{ margin: "0 0 15px 0" }}>
            <strong>Модифікації</strong>
          </h3>
          <ul className={styles.font18}>
            {modifiedList.map((modification, index) => (
              <li key={index}>{modification}</li>
            ))}
          </ul>
        </TextBlock>
      )}
      {disadvantagesList.length > 0 && (
        <TextBlock
          color={modifiedList.length > 0 ? "#fff" : "#dbf6fa"}
          className="cardText"
        >
          <h3 style={{ margin: "0 0 15px 0" }}>
            <strong>Недоліки</strong>
          </h3>
          <ul className={styles.font18}>
            {disadvantagesList.map((disadvantage, index) => (
              <li key={index}>{disadvantage}</li>
            ))}
          </ul>
        </TextBlock>
      )}
      {hasServiceHistory && (
        <TextBlock
          color={disadvantagesList.length > 0 ? "#dbf6fa" : "#fff"}
          className="cardText"
        >
          <h3 style={{ margin: "0 0 15px 0" }}>
            <strong>Сервісна історія</strong>
          </h3>
          <p className={styles.font18}>{car.serviceHistoryDescription}</p>
          <GalleryServiceHistory images={car.serviceHistoryImages} />
        </TextBlock>
      )}
      {car.ownerResponses.length > 0 && (
        <TextBlock color="#fff" className="cardText">
          <h3 style={{ margin: "0 0 15px 0" }}>
            <strong>Відповіді на питання від власника</strong>
          </h3>
          <SliderResponses
            responses={car.ownerResponses}
            handleLinkClick={handleLinkClick}
          />
          <ModalAllOwnerResponses
            isModalOpen={isResponseModalOpen}
            responses={car.ownerResponses}
            onClose={toggleResponseModal}
          />
          <div className={styles.allResponses}>
            <button onClick={() => setIsResponseModalOpen(true)}>
              Переглянути всі ({car.ownerResponses.length})
            </button>
          </div>
        </TextBlock>
      )}
    </>
  );
};

export default CarDescription;
