import React, { createContext, useState, useContext, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import {
  Login,
  ConfirmRegistration,
  ResetPassword,
} from "../Repositories/AccountRepository";
import UserModel from "../Models/UserModel.Js";
import { useApi } from "./ApiContext";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const apiUrl = useApi();

  useEffect(() => {
    const storedAccessToken = localStorage.getItem("CarCoolAccessToken");
    if (storedAccessToken && storedAccessToken !== "null") {
      const storedUser = decodeJwtToken(storedAccessToken);
      if (storedUser) {
        setUser(storedUser);
      }
    }
    setLoading(false);

    const handleStorageChange = (event) => {
      if (event.key === "isAuthenticated") {
        if (event.newValue === "false") {
          setUser(null);
        } else if (event.newValue === "true") {
          const storedAccessToken = localStorage.getItem("CarCoolAccessToken");
          if (storedAccessToken) {
            try {
              const newUser = decodeJwtToken(storedAccessToken);
              if (newUser) {
                setUser(newUser);
              }
            } catch (error) {
              console.error(
                "Error decoding token in storage change event:",
                error
              );
              setUser(null);
            }
          }
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const login = async (formData) => {
    try {
      const response = await Login(formData, apiUrl);
      if (response.responseData != null) {
        const decodedUser = decodeJwtToken(response.responseData.accessToken);
        if (decodedUser !== null) {
          setUser(decodedUser);
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem(
            "CarCoolAccessToken",
            response.responseData.accessToken
          );
          localStorage.setItem(
            "CarCoolRefreshToken",
            response.responseData.refreshToken
          );
          return { success: true, data: decodedUser };
        }
        return { success: false, message: "Invalid JWT token" };
      } else {
        return { success: false, message: response.error };
      }
    } catch (error) {
      console.error("Login error:", error);
      return { success: false, message: error.message };
    }
  };

  const registration = async (formData) => {
    try {
      const response = await ConfirmRegistration(formData, apiUrl);
      if (response.responseData != null) {
        const decodedUser = decodeJwtToken(response.responseData.accessToken);
        if (decodedUser !== null) {
          setUser(decodedUser);
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem(
            "CarCoolAccessToken",
            response.responseData.accessToken
          );
          localStorage.setItem(
            "CarCoolRefreshToken",
            response.responseData.refreshToken
          );
          return { success: true, data: decodedUser };
        }
        return { success: false, message: "Invalid JWT token" };
      } else {
        return { success: false, message: response.error };
      }
    } catch (error) {
      console.error("Registration error:", error);
      return { success: false, message: error.message };
    }
  };

  const resetPassword = async (formData) => {
    try {
      const response = await ResetPassword(formData, apiUrl);
      if (response.responseData != null) {
        const decodedUser = decodeJwtToken(response.responseData.accessToken);
        if (decodedUser !== null) {
          setUser(decodedUser);
          localStorage.setItem("isAuthenticated", "true");
          localStorage.setItem(
            "CarCoolAccessToken",
            response.responseData.accessToken
          );
          localStorage.setItem(
            "CarCoolRefreshToken",
            response.responseData.refreshToken
          );
          return { success: true, data: decodedUser };
        }
        return { success: false, message: "Invalid JWT token" };
      } else {
        return { success: false, message: response.error };
      }
    } catch (error) {
      console.error("Reset password error:", error);
      return { success: false, message: error.message };
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.setItem("isAuthenticated", "false");
    localStorage.removeItem("CarCoolAccessToken");
    localStorage.removeItem("CarCoolRefreshToken");
  };

  const isAuthenticated = () => {
    return user !== null;
  };

  const decodeJwtToken = (token) => {
    if (!token) {
      return null;
    }
    try {
      const decodedToken = jwtDecode(token);
      //const now = Date.now() / 1000;

      // if (decodedToken.exp && decodedToken.exp < now) {
      //   console.error("Token expired");
      //   return null;
      // }

      const decodedUser = {
        ...UserModel,
        ID: decodedToken.ID || "",
        username: decodedToken.username || "",
        firstName: decodedToken.firstName || "",
        surname: decodedToken.surname || "",
        email: decodedToken.email || "",
        phoneNumber: decodedToken.phoneNumber || "",
        role: decodedToken.role || "",
        avatar: decodedToken.avatar || "",
      };

      return decodedUser;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        registration,
        logout,
        isAuthenticated,
        loading,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
