import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AccordionList from "./AccordionList";
import styles from "./CarDescription.module.css";

const ModalAllOwnerResponses = ({ isModalOpen, onClose, responses = [] }) => {
  const ownerResponse =
    responses?.map((response, index) => ({
      id: index + 1,
      title: response?.question || "",
      text: response?.answer || "",
      userId: response?.userID || "",
      user: response?.userName || "",
      avatar: response?.userAvatar || "",
    })) || [];

  const handleModalClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };
  return (
    <Modal
      isOpen={isModalOpen}
      onClick={handleModalClick}
      toggle={onClose}
      contentClassName={styles.modalContent}
      className={styles.customModal}
    >
      <ModalHeader toggle={onClose} className={styles.modalHeader}>
        Всі відповіді власника
      </ModalHeader>
      <ModalBody className={styles.modalBody}>
        <AccordionList questions={ownerResponse} />
      </ModalBody>
    </Modal>
  );
};

export default ModalAllOwnerResponses;
