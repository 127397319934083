import React, { useEffect, useRef, useState } from "react";
import CommentList from "./CommentList";
import Button from "./Button";
import styles from "./Chat.module.css";
import { useAuth } from "../Contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";

const Message = ({
  message,
  deleteMessage,
  handleReply,
  toggleReplies,
  handleReplySend,
  handleReplyInputChange,
  replyMessages,
  replyTextareaRefs,
  deleteComment,
}) => {
  const lengthReplies = message.comments.length;
  const repliesLabel =
    lengthReplies === 1
      ? "Відповідь"
      : lengthReplies >= 2 && lengthReplies <= 4
      ? "Відповіді"
      : "Відповідей";

  const [replyText, setReplyText] = useState("");
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      adjustTextareaHeight(textareaRef.current);
    }
  }, [replyText]);

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  useEffect(() => {
    if (message.showReplyInput) {
      setReplyText(replyMessages[message.id] || "");
      if (textareaRef.current) {
        adjustTextareaHeight(textareaRef.current);
      }
    } else {
      setReplyText("");
    }
  }, [message.showReplyInput, message.id, replyMessages]);

  const handleToggleReply = (parentId) => {
    if (isAuthenticated()) {
      if (!message.showReplyInput) {
        setReplyText(replyMessages[message.id] || "");
      }
      handleReply(parentId);
    } else {
      navigate("/login", { state: { from: location } });
    }
  };

  const handleLinkClick = (event) => {
    if (!isAuthenticated()) {
      event.preventDefault();
      navigate("/login", { state: { from: location } });
    }
  };

  return (
    <div
      className={`${styles.message} ${
        message.isSystemMessage ? styles.systemMessage : ""
      }`}
    >
      {message.isSystemMessage ? (
        <div className={styles.systemMessageContent}>
          <div className={styles.messageContent}>{message.text}</div>
        </div>
      ) : (
        <div className={styles.messageHeader}>
          <div>
            <a
              href={`Profile?userId=${message.userId}`}
              className={styles.userUrl}
              onClick={(event) => handleLinkClick(event)}
            >
              <img
                src={message.userImg}
                alt="user"
                className={styles.userImg}
              />
              <span className={styles.userName}>{message.userName}</span>
            </a>
          </div>
          {user?.role === "Moderator" && (
            <Button
              onClick={() => deleteMessage(message.id)}
              className={styles.deleteButton}
            >
              <img src="/images/Dump.png" alt="Dump" />
            </Button>
          )}
        </div>
      )}
      {!message.isSystemMessage && (
        <div className={styles.messageContent}>{message.text}</div>
      )}
      <div className={styles.groupReplyButton}>
        {!message.isSystemMessage && (
          <Button
            onClick={() => handleToggleReply(message.id)}
            className={styles.replyButton}
          >
            Відповісти
          </Button>
        )}
        {message.comments && message.comments.length > 0 && (
          <div>
            <Button
              onClick={() => toggleReplies(message.id, false)}
              className={styles.toggleReplyButton}
            >
              <img
                src="/images/arrow_blue.png"
                alt="arrow"
                className={`${styles.arrowInButton} ${
                  message.showReplies ? styles.rotate : ""
                }`}
              />
              {`${message.comments.length} ${repliesLabel}`}
            </Button>
          </div>
        )}
      </div>
      {message.showReplyInput && !message.isSystemMessage && (
        <div className={styles.replyInputContainer}>
          <textarea
            ref={(el) => {
              replyTextareaRefs.current[message.id] = el;
              textareaRef.current = el;
            }}
            className={styles.textarea}
            placeholder="Коментар..."
            value={replyText}
            onChange={(e) => {
              setReplyText(e.target.value);
              handleReplyInputChange(e, message.id);
              adjustTextareaHeight(e.target);
            }}
            rows={1}
          />
          <Button
            onClick={() => handleReplySend(message.id, replyText)}
            className={styles.sendButton}
          >
            <img src="/images/Send.png" alt="send" />
          </Button>
        </div>
      )}
      {message.showReplies && (
        <CommentList
          comments={message.comments}
          parentId={message.id}
          deleteComment={deleteComment}
          handleLinkClick={handleLinkClick}
        />
      )}
    </div>
  );
};

export default Message;
