import React from "react";
import UserCars from "../UI/UserCars";

const Announcements = () => {
  return (
    <UserCars
      urlController={"Profile/GetListAnnouncement?userId="}
      title={`Мої оголошення `}
    />
  );
};

export default Announcements;
