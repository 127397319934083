import React, { useState, useEffect } from "react";
import ListCard from "../Moderation/ListCard";
import styles from "./Home.module.css";
import useFetchData from "../Hooks/useFetchData";

const InModeration = () => {
  const [cars, setCars] = useState([]);

  const { fetchData } = useFetchData("Moderator/GetCarsInModeration");

  useEffect(() => {
    fetchData()
      .then((data) => setCars(data))
      .catch((err) => console.error(err));
  }, [fetchData]);

  return (
    <div className="row justify-content-between" style={{ marginLeft: "0" }}>
      <div
        className={`d-flex justify-content-between align-items-center ${styles.topRowSort}`}
      >
        <div className="d-flex align-items-center">
          <p
            className="text-center"
            style={{ marginBottom: 0, marginRight: "20px" }}
          >
            Всього лотів на модерацію {cars.length}
          </p>
        </div>
      </div>
      <div className="col-xxl-12 col-xl-12" style={{ padding: "0" }}>
        <ListCard cars={cars} />
      </div>
    </div>
  );
};

export default InModeration;
