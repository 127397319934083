import React, { useEffect, useState } from "react";
import TextBlock from "../UI/TextBlock";
import FormRowSetings from "../UI/FormRowSetings";
import FormRowSwitchesSetings from "../UI/FormRowSwitchesSetings";
import styles from "./ProfileContent.module.css";
import FormRowSetingsLink from "../UI/FormRowSetingsLink";
import FormRowSetingsUploadFile from "../UI/FormRowSetingsUploadFile";
import { useAuth } from "../Contexts/AuthContext";
import { useToast } from "../Contexts/ToastContext";
import useFetchData from "../Hooks/useFetchData";

const Settings = () => {
  const { user } = useAuth();

  useEffect(() => {}, [user]);

  const { showToast } = useToast();
  const [settings, setSettings] = useState({
    isAnnouncementsStoryHidden: false,
    isBidsStoryHidden: false,
  });

  const { fetchData } = useFetchData(
    `Profile/GetUserProfileSettings/${user.ID}`
  );

  useEffect(() => {
    fetchData()
      .then((data) => setSettings(data))
      .catch((err) =>
        showToast("Помилка", `Error fetching user data: ${err}`, "danger")
      );
  }, [fetchData, user.ID, showToast]);

  return (
    <>
      <TextBlock color="#dbf6fa" className="cardText">
        <h2 className={styles.h2}>
          <strong>Аккаунт</strong>
        </h2>
        <FormRowSetingsLink
          label="Google аккаунт"
          text={user.email}
          image="/images/Google.png"
        />
        <div className="row mb-3">
          <div className="col-sm-8 d-flex align-items-center">
            <div className="form-group">
              <label className={styles.lableSetings}>
                <strong>Пароль</strong>
              </label>
            </div>
          </div>
          <div className="col-sm-4 d-flex justify-content-end align-items-end">
            <button
              type="button"
              className={`float-right ${styles.btnPrimaryBlue}`}
            >
              <strong>Змінити пароль</strong>
            </button>
          </div>
        </div>
        <FormRowSetings
          label="Банківська карта"
          text={user.cardNumber}
          buttonText="Змінити карту"
        />
        <FormRowSetings
          label="Номер телефону"
          text={user.phoneNumber}
          buttonText="Змінити номер"
        />
        <FormRowSetingsUploadFile
          key={1}
          label="Верифікація аккаунта"
          text="Необхідно завантажити фото паспорта та РНОКПП"
        />
        <strong>
          <FormRowSwitchesSetings
            key={2}
            label="Приховати історію оголошень"
            type={"IsAnnouncementsStoryHidden"}
            value={settings.isAnnouncementsStoryHidden}
            userId={user.ID}
          />
        </strong>
        <strong>
          <FormRowSwitchesSetings
            key={3}
            label="Приховати історію ставок"
            type={"IsBidsStoryHidden"}
            value={settings.isBidsStoryHidden}
            userId={user.ID}
          />
        </strong>
      </TextBlock>
      <TextBlock color="#fff" className="cardText">
        <h2 className={styles.h2}>
          <strong>Загальні сповіщення</strong>
        </h2>
        <FormRowSwitchesSetings
          key={4}
          label="Повідомте мене, коли мене згадають у коментарях"
          value={true}
          userId={user.ID}
        />
        <FormRowSwitchesSetings
          key={5}
          label="Повідомте мене, коли хтось відповість мені в коментарях"
          value={true}
          userId={user.ID}
        />
        <FormRowSwitchesSetings
          key={6}
          label="Вмикати звук під час розміщення ставок (лише для ПК)"
          value={true}
          userId={user.ID}
        />
      </TextBlock>
      <TextBlock color="#dbf6fa" className="cardText">
        <h2 className={styles.h2}>
          <strong>Сповіщення про список спостереження</strong>
        </h2>
        <FormRowSwitchesSetings
          key={7}
          label="Повідомте мене за 1 годину до закінчення аукціону"
          value={true}
          userId={user.ID}
        />
        <FormRowSwitchesSetings
          key={8}
          label="Повідомте мене, коли з'являться нові пропозиції"
          value={true}
          userId={user.ID}
        />
        <FormRowSwitchesSetings
          key={9}
          label="Повідомте мене, коли з'являться нові коментарі"
          value={true}
        />
        <FormRowSwitchesSetings
          key={10}
          label="Повідомте мене, коли будуть відповіді на запитання"
          value={true}
          userId={user.ID}
        />
        <FormRowSwitchesSetings
          key={11}
          label="Повідомте мене про результати аукціонів"
          value={true}
          userId={user.ID}
        />
        <FormRowSwitchesSetings
          key={12}
          label="Отримувати ці сповіщення також на електронну пошту"
          value={true}
          userId={user.ID}
        />
      </TextBlock>
      <TextBlock color="#fff" className="cardText">
        <h2 className={styles.h2}>
          <strong>Сповіщення учасника торгів</strong>
        </h2>
        <FormRowSwitchesSetings
          key={13}
          label="Повідомте мене, коли мою ставку переб'ють"
          value={true}
          userId={user.ID}
        />
        <FormRowSwitchesSetings
          key={14}
          label="Повідомте мене після аукціону, якщо я не виграю"
          value={true}
          userId={user.ID}
        />
      </TextBlock>
      <TextBlock color="#dbf6fa" className="cardText">
        <h2 className={styles.h2}>
          <strong>Сповіщення продавця</strong>
        </h2>
        <FormRowSwitchesSetings
          key={15}
          label="Надсилайте нові коментарі на електронну пошту"
          value={true}
          userId={user.ID}
        />
        <FormRowSwitchesSetings
          key={16}
          label="Надсилайте нові пропозиції на електронну пошту"
          value={true}
          userId={user.ID}
        />
      </TextBlock>
    </>
  );
};

export default Settings;
