import React from "react";
import styles from "./CarTitle.module.css";

const CarTitle = ({ title }) => {
  return (
    <div className={styles.header}>
      <h3>
        <strong>{`${title.productionYear} ${title.brand} ${title.model}`}</strong>
      </h3>
      <div className={styles.tags}>
        <span className={styles.tag}>Кузов {title.body}</span>
        <span className={styles.tag}>Привід {title.drive}</span>
        <span className={styles.tag}>КПП {title.gearbox}</span>
        <span className={styles.tag}>Двигун {title.engine}</span>
        <span className={styles.tag}>Паливо {title.fuelType}</span>
      </div>
    </div>
  );
};

export default CarTitle;
