import React, { useState } from "react";
import styles from "../ProfilePages/ProfileContent.module.css";

const FormRowSetings = ({ label, text, buttonText, buttonType }) => {
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState(text);
  const [previousValue, setPreviousValue] = useState(text);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSave = () => {
    setPreviousValue(inputValue);
  };

  const handleCancel = () => {
    setInputValue(previousValue);
    setShowInput(false);
  };

  return (
    <div className="row mb-3">
      <div className="col-sm-8">
        <div className="form-group">
          <label className={styles.lableSetings}>
            <strong>{label}</strong>
          </label>
          <div className="d-flex align-items-center mt-3">
            {showInput ? (
              <input
                type={!buttonType ? "text" : buttonType}
                className={styles.formSetingsInput}
                value={inputValue}
                onChange={handleChange}
              />
            ) : (
              <p className={styles.formSetingsText}>{inputValue}</p>
            )}
          </div>
        </div>
      </div>
      <div className="col-sm-4 d-flex justify-content-end align-items-end">
        <>
          <button
            type="button"
            className={`float-right ${styles.btnPrimaryBlue}`}
            onClick={() => {
              setShowInput(!showInput);
              handleSave();
            }}
          >
            <strong>{showInput ? "Зберегти" : buttonText}</strong>
          </button>
          {showInput && (
            <button
              type="button"
              className={`float-right ${styles.btnPrimaryBlue}`}
              onClick={handleCancel}
              style={{ marginLeft: "10px" }}
            >
              <strong>Відмінити</strong>
            </button>
          )}
        </>
      </div>
    </div>
  );
};

export default FormRowSetings;
