import React from "react";

const ScrollArrow = ({ arrowClass, scroll }) => {
  return (
    <div onClick={scroll}>
      <img src="/images/arrow_down.png" alt="arrow" className={arrowClass} />
    </div>
  );
};

export default ScrollArrow;
