import { useRef, useEffect } from "react";
import createSignalRConnectionForLot from "../Services/createSignalRConnectionForLot";

const useSignalRForLot = (lotId, setLotAuctionData, setLotWinner) => {
  const connectionRef = useRef(null);

  useEffect(() => {
    const connection = createSignalRConnectionForLot(lotId);
    connectionRef.current = connection;

    connection.on("ReceiveUpdate", (lotUpdateModel) => {
      setLotAuctionData(lotUpdateModel);
    });

    connection.on("ReceiveLotWinner", (lotWinner) => {
      setLotWinner(lotWinner);
    });

    return () => {
      connection.stop();
    };
  }, [lotId, setLotAuctionData, setLotWinner]);

  return connectionRef;
};

export default useSignalRForLot;
