import React, { useState } from "react";
import styles from "../ProfilePages/ProfileContent.module.css";

const FormRowSetingsLink = ({ label, text, image }) => {
  const [isLinked, setIsLinked] = useState(true);

  const handleLinkToggle = () => {
    setIsLinked(!isLinked);
  };

  return (
    <div className="row mb-3">
      <div className="col-sm-8">
        <div className="form-group">
          <label className={styles.lableSetings}>
            <strong>{label}</strong>
          </label>
          <div className="d-flex align-items-center mt-3">
            <img src={image} alt="Icon" className={styles.imgGoogle} />
            {isLinked ? (
              <p className={styles.formSetingsText}>{text}</p>
            ) : (
              <p className={styles.formSetingsText}> </p>
            )}
          </div>
        </div>
      </div>
      <div className="col-sm-4 d-flex justify-content-end align-items-end">
        <button
          type="button"
          className={`float-right ${styles.btnPrimaryBlue}`}
          onClick={handleLinkToggle}
        >
          <strong>
            {isLinked ? "Відв’язати аккаунт" : "Прив'язати аккаунт"}
          </strong>
        </button>
      </div>
    </div>
  );
};

export default FormRowSetingsLink;
