import React from "react";
import UserCars from "../UI/UserCars";

const ChosenOnes = () => {
  return (
    <UserCars
      urlController={"Profile/GetListChosenOnes?userId="}
      title={`Мої обрані `}
      hideClickingFavorite={true}
    />
  );
};

export default ChosenOnes;
