import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../Contexts/AuthContext";
import useFetchData from "../Hooks/useFetchData";
import CardList from "../HomeComponents/CardList";

const UserCars = ({ urlController, IdUser, title, hideClickingFavorite }) => {
  const { user, isAuthenticated } = useAuth();
  const [userId, setUserId] = useState(null);
  const [url, setUrl] = useState("");
  const { fetchData } = useFetchData(userId ? url : null);
  const [listData, setListData] = useState([]);

  useEffect(() => {
    if (isAuthenticated() && user) {
      if (IdUser) {
        setUserId(IdUser);
      } else {
        setUserId(user.ID);
      }
    }
  }, [user, isAuthenticated, IdUser]);

  useEffect(() => {
    if (userId) {
      setUrl(urlController + userId);
    }
  }, [userId, urlController]);

  useEffect(() => {
    fetchData()
      .then((data) => setListData(data))
      .catch((err) => console.error("Error fetching data:", err));
  }, [fetchData]);

  const handleFavoriteToggle = useCallback(
    (id) => {
      if (hideClickingFavorite) {
        setListData((prevList) => {
          const updatedList = prevList.filter((car) => car.lotID !== id);
          return updatedList;
        });
      } else {
        setListData((prevList) => {
          const updatedList = prevList.map((car) =>
            car.lotID === id ? { ...car, favorite: !car.favorite } : car
          );
          return updatedList;
        });
      }
    },
    [hideClickingFavorite]
  );

  return (
    <div className="justify-content-between">
      <div className="d-flex justify-content-between">
        <p
          className="text-center"
          style={{ marginBottom: 0, marginRight: "20px" }}
        >
          {title + listData.length}
        </p>
      </div>
      <div className="col-xxl-12">
        <CardList
          filteredCards={listData}
          toggleFavorite={handleFavoriteToggle}
        />
      </div>
    </div>
  );
};

export default UserCars;
