import React from "react";
import styles from "./CardCar.module.css";

const FavoritesButton = ({
  isFavorite,
  handleFavoriteClick,
  isInCardDetails,
}) => {
  const handleClick = (event) => {
    event.stopPropagation();
    handleFavoriteClick();
  };

  return (
    <button
      className={`${styles.cardCarFavorite} ${
        isInCardDetails ? styles.cardDetailsFavorite : ""
      }`}
      onClick={handleClick}
    >
      <img
        alt={isFavorite ? "Star1" : "Star"}
        src={isFavorite ? "/images/starfull.png" : "/images/Star.png"}
        className={`${styles.imgStar} ${
          isInCardDetails ? styles.cardDetailsImgStar : ""
        }`}
      />
    </button>
  );
};

export default FavoritesButton;
