import { useRef, useEffect } from "react";
import createSignalRConnectionForChat from "../Services/createSignalRConnectionForChat";

const useSignalRForChat = (lotId, setMessages, setMessageCount) => {
  const connectionRef = useRef(null);

  useEffect(() => {
    const connection = createSignalRConnectionForChat(lotId);
    connectionRef.current = connection;

    connection.on("ReceiveMessage", (message) => {
      if (message.text && !message.text.includes("has joined the group")) {
        const newMessageObj = {
          id: message.id,
          userId: message.userId,
          userName: message.userName,
          userImg: message.userImg,
          text: message.text,
          comments: [],
        };
        setMessages((prevMessages) => [...prevMessages, newMessageObj]);
      }
    });

    connection.on("ReceiveComment", (parentId, comment) => {
      setMessages((prevMessages) =>
        prevMessages.map((message) => {
          if (message.id === parentId) {
            return {
              ...message,
              comments: [
                ...message.comments,
                {
                  id: Date.now().toString(),
                  userId: comment.userId,
                  userName: comment.userName,
                  userImg: comment.userImg,
                  text: comment.text,
                },
              ],
            };
          }
          return message;
        })
      );
    });

    connection.on("ReceiveSystemMessage", (message) => {
      if (message.text && !message.text.includes("has joined the group")) {
        const newMessageObj = {
          id: Date.now().toString(),
          text: message.text,
          isSystemMessage: true,
          comments: [],
        };
        setMessages((prevMessages) => [...prevMessages, newMessageObj]);
      }
    });

    connection.on("MessageDeleted", (messageId) => {
      setMessages((prevMessages) =>
        prevMessages.filter((message) => message.id !== messageId)
      );
    });

    connection.on("CommentDeleted", (parentId, commentId) => {
      setMessages((prevMessages) =>
        prevMessages.map((message) => {
          if (message.id === parentId) {
            return {
              ...message,
              comments: message.comments.filter(
                (comment) => comment.id !== commentId
              ),
            };
          }
          return message;
        })
      );
    });

    connection.on("UpdateMessageCount", (messageCount) => {
      setMessageCount(messageCount);
    });

    return () => {
      connection.stop();
    };
  }, [lotId, setMessages, setMessageCount]);

  return connectionRef;
};

export default useSignalRForChat;
