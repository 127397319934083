import React, { useState } from "react";
import Image from "./Image";
import ImageModal from "./ImageModal";
import styles from "./Gallery.module.css";

const GalleryServiceHistory = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isGridView, setIsGridView] = useState("image");

  const openModal = (image) => {
    setSelectedImage(image);
    setIsGridView("image");
  };

  const openGridView = () => {
    setSelectedImage(images[0]);
    setIsGridView("grid");
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className={`col-xxl-12 ${styles.gallery}`}>
      {images.slice(0, 4).map((image, index) =>
        index < 3 ? (
          <Image
            index={index}
            key={index}
            src={image}
            onClick={() => openModal(image, index)}
            service={true}
          />
        ) : (
          <button
            className={`${styles.galleryButtonAll} ${styles.imageService}`}
            key={index + 1}
            style={{ padding: "0", margin: "10px 0 10px 0" }}
            onClick={openGridView}
          >
            <img
              style={{ margin: "0" }}
              src={image}
              alt={`Gallery ${index + 2}`}
            />
            <span>Всі Фото ({images.length})</span>
          </button>
        )
      )}
      {images.length < 4 && (
        <button
          className={`${styles.galleryButtonAllService} ${styles.galleryButtonAllButton}`}
          style={{ padding: "0", margin: "10px 0" }}
          onClick={openGridView}
        >
          <span>Всі Фото ({images.length})</span>
        </button>
      )}
      {selectedImage !== null && (
        <ImageModal
          images={images}
          initialImage={selectedImage}
          isView={isGridView}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default GalleryServiceHistory;
