import React from "react";

const NoLotsFound = () => {
  return (
    <h1 style={{ margin: "50px" }}>
      <img
        alt="not_found"
        src="/images/page-not-found.png"
        style={{ width: "70px" }}
      />
      Лоти не знайдено
    </h1>
  );
};

export default NoLotsFound;
