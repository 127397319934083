import React from "react";
import styles from "../HomeComponents/CardList.module.css";

const ButtonloadMore = ({ load }) => {
  return (
    <button onClick={load} className={styles.btnVisibleCardCar}>
      <img
        alt="Load"
        src="/images/sync.png"
        className={styles.imgVisibleCardCar}
      />{" "}
      Показати ще
    </button>
  );
};

export default ButtonloadMore;
