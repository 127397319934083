import * as signalR from "@microsoft/signalr";

const url = `${process.env.REACT_APP_API_URL}/personalizedSystemMessageHub`;

const createSignalRConnectionForPersonalizedMessage = (userId) => {
  const connection = new signalR.HubConnectionBuilder()
    .withUrl(url, {
      accessTokenFactory: () => localStorage.getItem("token"),
    })
    .withAutomaticReconnect()
    .configureLogging(signalR.LogLevel.Information)
    .build();

  connection
    .start()
    .then(() => {
      connection
        .invoke("Join", userId)
        .catch((err) => console.error(`Failed to join group ${userId}: `, err));
    })
    .catch((err) =>
      console.error(`SignalR Connection Error for user ${userId}: `, err)
    );

  return connection;
};

export default createSignalRConnectionForPersonalizedMessage;
